import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import hr from "../assets/hr1.png"
const parse = require('html-react-parser')

const GiftOffer = () => {
    const { productName } = useParams()
    const [allData, setAllData] = useState([])
    const [htmlText, setHtmlText] = useState("")

    useEffect(() => {
        axios.get((`/api/gifts/${productName}`)).then(dt => {
            setAllData(dt?.data?.data);
            setHtmlText(JSON.parse(dt?.data?.data?.description))
        })
    }, [])

    return (
        <div className='container'>
            <h2 className='text-[30px] md:text-[50px] text-white pt-10 uppercase '>GIFT Cards</h2>
            <img className=' w-[200px] -mt-2 mb-2' src={hr} alt="" />
            <h3 className='md:text-[20px] text-[18px] text-white font-medium pb-4  uppercase'>HOME / GIFT Cards</h3>
            <div className='grid bg-[#222222] px-6 py-6 items-center time grid-cols-12 mt-6 md:gap-8'>
                <div className='col-span-12 md:col-span-5'>
                    <img className=' w-[100%] md:w-full rounded-md' src={allData?.photo} alt="" />
                </div>
                <div className="col-span-12 md:col-span-7 text-white ">
                    <div className='flex justify-between items-center'>
                        <div>
                            <h3 className='text-[25px] md:text-[35px] '>{allData?.title}</h3>
                            <p className='text-base md:text-lg'>Contact at Telegram and whatsapp only</p>
                            <a target="_blank" href={allData?.whatsapp} className="relative inline-flex mt-8 mr-6 items-center px-12 py-3 overflow-hidden md:text-lg text-base font-medium text-white border-2 border-white hover:border-[#DD2476] rounded-full hover:text-white group hover:bg-gray-50">
                                <span className="absolute left-0 block w-full h-0 transition-all bg-gradient-to-r from-[#FA5252] to-[#DD2476] opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                                <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                </span>
                                <span className="relative">Whatsapp</span>
                            </a>
                            <a target="_blank" href={allData?.telegram} className="relative inline-flex mt-8 items-center px-12 py-3 overflow-hidden md:text-lg text-base font-medium text-white border-2 border-white hover:border-[#DD2476] rounded-full hover:text-white group hover:bg-gray-50">
                                <span className="absolute left-0 block w-full h-0 transition-all bg-gradient-to-r from-[#FA5252] to-[#DD2476] opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                                <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                </span>
                                <span className="relative">Telegram</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-[#222222] px-6 text-white p  md:px-10 py-4 md:py-6 rounded-lg mt-8'>
                <h2 className='text-[25px] md:text-[35px] ' >Description</h2>
                <img src={hr} alt="" />
                <p className='font-medium text-lg leading-10 mt-3 '>{parse(htmlText)}</p>
            </div>
        </div>
    );
};

export default GiftOffer;