import axios from 'axios';
import  { useEffect, useState } from 'react';
import Moment from 'react-moment';
import {Link, useNavigate} from 'react-router-dom';
import UseData from '../Hooks/UseData';
import PageTitle from '../PageTitle';
import {toast} from 'react-toastify';

const Wallet = () => {
    const { wallet, setWallet } = UseData()

    const url = window.location.search;
    const history = useNavigate();
    useEffect(() => {
      const params = new URLSearchParams(url);
      const invoice_id = params.get("invoice_id");
  
      if (invoice_id) {
        axios
          .post("/api/verify-wallet-payment", {
            invoice_id: invoice_id,
          })
          .then((response) => {
            if(response?.data?.data?.payment_status === "Paid" && response?.data?.data?.order_status === "Processing"){
              history("/wallet");
              toast.info("Order Place Successfully!", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
               });
            }
            else if(response?.data?.data?.payment_status === "Unpaid" && response?.data?.data?.order_status === "Pending"){
              toast.info("Payment is Processing. Please wait..", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
               });
            }
            else{
              history("/wallet");
              toast.error(response?.data?.message ?? 'Something Wrong', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
               });
            }
          })
          .catch((error) => {
            console.error("Error verifying payment:", error);
          });
      }
    }, [url, history]);

    useEffect(() => {
        axios.get("/api/user/wallets").then((response) => {
            setWallet(response)
        })
            .catch((error) => {  
            });
    }, [])

    return (
        <>
                <PageTitle title="Wallet - FNZ Gaming Shop" description="Page Description"></PageTitle>
       
        <div className='container py-16 md:py-0'>
            <div className="grid grid-cols-2 items-center ">
                <div className='hidden md:block md:col-span-1'>
                    <img src="https://static.vecteezy.com/system/resources/previews/010/175/185/non_2x/3d-business-wallet-finance-illustration-free-png.png" alt="" />
                </div>
                <div className=' col-span-2 md:col-span-1'>
                    <div className='bg-[#222222] text-center rounded-lg py-6 px-8'>
                        <h2 className='text-[30px] md:text-[50px] text-white '  >MY WALLET</h2>
                        <div className='-mt-5'>
                            <span className='w-1 mx-2 h-[1px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-2 mx-2 h-[2px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-4 mx-2 h-[3px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-8 mx-2 h-[4px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-4 mx-2 h-[3px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-2 mx-2 h-[2px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-1 mx-2 h-[1px] rounded-lg bg-[#fbc205] inline-block '></span>
                        </div>
                        <br />
                        <Link to='/mobileRecharge' className="relative inline-flex mb-2 md:mb-0 md:mr-2 items-center justify-center p-4 px-12 py-3 overflow-hidden font-medium text-white transition duration-300 ease-out border-2 border-white rounded-full shadow-md group">
                            <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-gradient-to-r from-[#FA5252] to-[#DD2476] group-hover:translate-x-0 ease">
                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                            </span>
                            <span className="absolute flex items-center justify-center w-full h-full text-white md:text-lg text-base transition-all duration-300 transform group-hover:translate-x-full ease">Recharge</span>
                            <span className="relative md:text-lg text-base invisible"> Recharge</span>
                        </Link>
                        <button className="relative md:ml-2 inline-flex items-center justify-center p-4 px-12 py-3 overflow-hidden font-medium text-white transition duration-300 ease-out border-2 border-white rounded-full shadow-md group">
                            <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-gradient-to-r from-[#FA5252] to-[#DD2476] group-hover:translate-x-0 ease">
                                {wallet?.data?.balance} BDT
                            </span>
                            <span className="absolute flex items-center justify-center w-full h-full text-white md:text-lg text-base transition-all duration-300 transform group-hover:translate-x-full ease">BALANCE </span>
                            <span className="relative md:text-lg text-base invisible"> BALANCE </span>
                        </button>
                        <p className='text-white md:text-lg text-base mt-6 ' >USE WALLET BALANCE FOR FAST DELIVERY</p>
                    </div>
                    <div className='bg-[#222222] text-center rounded-lg py-6 px-8 mt-8'>
                        <h2 className='text-[30px] md:text-[50px] text-white ' >RECHARGE HISTORY</h2>
                        <div className='-mt-5'>
                            <span className='w-1 mx-2 h-[1px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-2 mx-2 h-[2px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-4 mx-2 h-[3px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-8 mx-2 h-[4px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-4 mx-2 h-[3px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-2 mx-2 h-[2px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-1 mx-2 h-[1px] rounded-lg bg-[#fbc205] inline-block '></span>
                        </div>
                        <br />
                        <div className='text-left'>
                            {
                                wallet?.data?.data?.map(item => (<p key={item?.id} className={`${item?.order_status ==="Completed" ? "text-white":"text-red-600"} md:text-lg tracking-wider text-base  `}  ><i className={`${item?.order_status ==="Completed" ? "text-white":"text-red-600"} fa-solid  mr-2 text-base fa-bolt `} ></i>You have recharged {item?.amount} tk at <Moment>{item?.order_date}</Moment></p>))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Wallet;