import React, { useState } from "react";
import Rating from "react-rating";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useEffect } from "react";
import axios from "axios";
import UseData from "../Hooks/UseData";
import { toast } from "react-toastify";
import PageTitle from "../PageTitle";
import reviewimg from "../../assets/review.json";
import Lottie from "lottie-react";
import userImg from "../../assets/user.png";

const Review = () => {
   const [text, setText] = useState("");
   const [rating, setRating] = useState();
   const [review, setReview] = useState([]);
   const { token } = UseData();
   useEffect(() => {
      axios.get("/api/review").then((dt) => {
         setReview(dt?.data?.data);
      });
   }, []);

   const handleSubmit = (e) => {
      e.preventDefault();
      if (rating > 0) {
         if (token) {
            const obj = {
               user_id: token?.data?.id,
               rating,
               review: text,
               name: token?.data?.name,
            };
            axios
               .post("/api/review-submit", obj)
               .then((dt) => {
                  if (dt?.data?.status) {
                     toast.success("Review Submit Successfully!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                     });
                     setRating(0);
                     setText(" ");
                  } else {
                     toast.error("Review Does not Submit!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                     });
                  }
               })
               .finally(() => {
                  setRating(0);
                  setText(" ");
               });
         } else {
            toast.info("Please Login First Then Try Again!", {
               position: "top-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "dark",
            });
         }
      } else {
         toast.info("Rating Required!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
         });
      }
   };
   return (
      <>
         <PageTitle
            title="Review - FNZ Gaming Shop"
            description="Page Description"
         ></PageTitle>
         <div className="container md:py-20">
            <div className="grid grid-cols-2 gap-8">
               <div className="col-span-2 md:col-span-1 md:hidden mt-8 flex items-center justify-center">
                  <Lottie
                     className="object-contain rounded-lg  md:w-[500px]"
                     animationData={reviewimg}
                     loop={true}
                  />
               </div>
               <div className="col-span-2 md:col-span-1">
                  <h2 className="text-[30px] md:text-[50px] text-center text-white ">
                     Rate Us
                  </h2>
                  <div className="-mt-5 text-center ">
                     <span className="w-1 mx-2 h-[1px] rounded-lg bg-[#fbc205] inline-block "></span>
                     <span className="w-2 mx-2 h-[2px] rounded-lg bg-[#fbc205] inline-block "></span>
                     <span className="w-4 mx-2 h-[3px] rounded-lg bg-[#fbc205] inline-block "></span>
                     <span className="w-8 mx-2 h-[4px] rounded-lg bg-[#fbc205] inline-block "></span>
                     <span className="w-4 mx-2 h-[3px] rounded-lg bg-[#fbc205] inline-block "></span>
                     <span className="w-2 mx-2 h-[2px] rounded-lg bg-[#fbc205] inline-block "></span>
                     <span className="w-1 mx-2 h-[1px] rounded-lg bg-[#fbc205] inline-block "></span>
                  </div>
                  <br />
                  <div className="text-center text-yellow-500">
                     <form onSubmit={handleSubmit}>
                        <Rating
                           onChange={(rate) => setRating(rate)}
                           className="text-center mx-auto"
                           emptySymbol="fa text-yellow-500 fa-star-o fa-2x"
                           fullSymbol="fa text-yellow-500 fa-star fa-2x"
                        />
                        <br />
                        <h3 className="text-[30px] font-extrabold text-[#787878] py-2  ">
                           Give Us Feedback
                        </h3>

                        <div className="relative z-0 w-full mt-[20px] mb-8 group">
                           <input
                              required
                              onChange={(e) => setText(e.target.value)}
                              value={text}
                              type="text"
                              name="name"
                              className="block text-left autofill:bg-transparent py-2.5 px-0 w-full text-xl text-block bg-transparent border-0 border-b-[2px] border-[#787878] appearance-none  tracking-wider focus:outline-none focus:ring-0 focus:border-[#787878] peer"
                              placeholder=" "
                           />
                           <label
                              htmlFor="name"
                              className="peer-focus:font-medium absolute text-2xl text-[#787878]  duration-300 left-0 transform -translate-y-6 scale-75 top-0 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#787878]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 text-left"
                           >
                              Rating Description *
                           </label>
                        </div>
                        <button
                           type="submit"
                           className=" rounded px-5 pb-2.5 pt-4 overflow-hidden group bg-white text-black relative hover:bg-gradient-to-r from-[#FA5252] to-[#DD2476]  hover:ring-2 hover:ring-offset-2 hover:ring-[#DD2476] transition-all ease-out duration-300 hover:text-white "
                        >
                           <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-[#111111] opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                           <span className="relative">সাবমিট করুন</span>
                        </button>
                     </form>
                  </div>
               </div>
               <div className="col-span-2 md:col-span-1 md:flex hidden items-center justify-center">
                  <Lottie
                     className="object-contain rounded-lg  md:w-[500px]"
                     animationData={reviewimg}
                     loop={true}
                  />
               </div>
            </div>
            <Swiper
               pagination={Pagination}
               spaceBetween={30}
               navigation={true}
               autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
               }}
               modules={[Navigation, Autoplay]}
               breakpoints={{
                  640: {
                     slidesPerView: 1,
                     spaceBetween: 20,
                  },
                  768: {
                     slidesPerView: 2,
                     spaceBetween: 25,
                  },
                  1025: {
                     slidesPerView: 3,
                     spaceBetween: 40,
                  },
               }}
               className="mySwiper mt-14"
            >
               {review?.map((item) => (
                  <SwiperSlide key={item?.id}>
                     <div className=" ">
                        <div className="group relative flex flex-col items-center hover:bg-[#e40a5d] cursor-pointer shadow-2xl md:p-9 p-6 duration-500 transition">
                           <div className="text-gray-100 group-hover:text-white flex flex-col items-center">
                              <svg
                                 className="text-yellow-500"
                                 width={26}
                                 height={27}
                                 viewBox="0 0 26 27"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 <g clipPath="url(#clip0)">
                                    <path
                                       d="M25.2578 14.3309H19.2969C19.3988 9.55819 20.6309 9.01642 22.1785 8.86178L22.7753 8.78051V3.53242L22.0874 3.57292C20.0666 3.69783 17.8323 4.09805 16.3417 6.11965C15.035 7.89183 14.459 10.7871 14.459 15.2316V23.4673H25.2578V14.3309Z"
                                       fill="currentColor"
                                    />
                                    <path
                                       d="M11.48 23.4673V14.3309H5.59859C5.70049 9.55819 6.89283 9.01642 8.44042 8.86178L8.99749 8.78051V3.53242L8.34931 3.57292C6.32844 3.69783 4.07421 4.09805 2.5836 6.11965C1.27707 7.89183 0.681147 10.7871 0.681147 15.2316V23.4673H11.48Z"
                                       fill="currentColor"
                                    />
                                 </g>

                                 <defs>
                                    <clipPath id="clip0">
                                       <rect
                                          width="24.5767"
                                          height={27}
                                          fill="white"
                                          transform="translate(25.2578 27) rotate(-180)"
                                       />
                                    </clipPath>
                                 </defs>
                              </svg>
                              <p className="text-gray-100 text-lg leading-normal text-center mt-4 h-12 w-full">
                                 {item.review.length > 60
                                    ? item.review.slice(0, 60) + "...."
                                    : item.review}
                              </p>
                           </div>
                           <div className="text-center my-2">
                              <Rating
                                 initialRating={item?.rating}
                                 readonly
                                 className="text-center mx-auto text-xs md:text-base"
                                 emptySymbol="fa text-yellow-500 fa-star-o fa-2x"
                                 fullSymbol="fa text-yellow-500 fa-star fa-2x"
                              />
                           </div>

                           <div className="flex flex-col items-center justify-center mt-2">
                              {item.photo ? (
                                 <img
                                    src={item.photo}
                                    alt="profile pictre"
                                    className="md:w-14 md:h-14 w-12 h-12 rounded-full"
                                 />
                              ) : (
                                 <img
                                    src={userImg}
                                    alt="profile pictre"
                                    className="md:w-14 md:h-14 w-12 h-12 rounded-full"
                                 />
                              )}
                              <p className="text-base font-semibold leading-4 my-2 text-gray-100">
                                 {item?.name}
                              </p>
                           </div>
                        </div>
                     </div>
                  </SwiperSlide>
               ))}
            </Swiper>
         </div>
      </>
   );
};

export default Review;
