import React, { useEffect, useState } from 'react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import axios from 'axios';
import UseData from '../Hooks/UseData';
import { toast } from 'react-toastify';
import badge from "../../assets/badge.png"
import PageTitle from '../PageTitle';
// import PageTitle from ""


const EditProfile = () => {
    const { token, setToken, setProfile, profile } = UseData()
    const [name, setName] = useState(token?.data?.name)
    const [favorite, setFavorite] = useState(token?.data?.favorite_game)
    const [photo, setPhoto] = useState('')

    const handlePhoto = (e) => {
        setPhoto(e.target.files[0])
    }
    const handleForm = (e) => {
        e.preventDefault();
        let formdata = new FormData();
        formdata.append("name", name);
        formdata.append("email", profile?.data?.email);
        formdata.append("mobile", token?.data?.mobile);
        formdata.append("favorite_game", favorite);
        formdata.append("image", photo);

        axios.post("/api/update-profile", formdata).then(dt => {
            setProfile(dt?.data);
            localStorage.setItem("userToken", JSON.stringify(dt?.data))
            setToken(dt?.data)
            toast.info('User Profile Update Successfully!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            })
        }).catch(error => {
            toast.error('Something Wrong! Please Try Again', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        })
    };

    return (
        <>
        <PageTitle title="Edit Profile - FNZ Gaming Shop" description="Page Description"></PageTitle>
        <div className='container pt-14 pb-10 md:py-20'>
            <div className="grid grid-cols-12 gap-8 ">
                <div className="col-span-12 md:col-span-4">
                    <div className="block p-6 md:p-8 rounded-lg shadow-lg bg-[#222222] text-center " >
                        <Zoom>
                            <div className="md:h-40 mx-auto w-28 h-28 md:w-40 object-cover rounded-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[1px] ">
                                <img
                                    alt="That Wanaka Tree, New Zealand by Laura Smetsers"
                                    src={`https://backend.fnzgamingshop.com${profile?.data?.photo}`}
                                    className='rounded-full w-[110px] h-[110px] md:w-[158px] md:h-[158px] '
                                />
                            </div>
                        </Zoom>
                        <h2 className='text-xl md:text-2xl pt-4 pb-3 text-white ' >{profile?.data?.name}</h2>
                        <h2 className='md:text-lg text-base text-white ' >{profile?.data?.user_id}</h2>
                    </div>
                </div>
                <div className="col-span-12 md:col-span-8">
                    <div className="block p-6 md:p-8 rounded-lg shadow-lg bg-[#222222]">
                        <h2 className='text-[30px] md:text-[50px] text-center text-white ' >Profile Settings</h2>
                        <div className='-mt-5 text-center '>
                            <span className='w-1 mx-2 h-[1px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-2 mx-2 h-[2px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-4 mx-2 h-[3px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-8 mx-2 h-[4px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-4 mx-2 h-[3px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-2 mx-2 h-[2px] rounded-lg bg-[#fbc205] inline-block '></span>
                            <span className='w-1 mx-2 h-[1px] rounded-lg bg-[#fbc205] inline-block '></span>
                        </div>
                        <br />
                        <form onSubmit={handleForm}>
                            <div className=" gap-4">
                                <div className="form-group mb-6">
                                    <label className='text-white md:text-lg text-base  tracking-wider ' htmlFor="exampleInput123">Full Name:</label>
                                    <input type="text" className="input-feild" id="exampleInput123"
                                        aria-describedby="emailHelp123" onChange={(e) => setName(e.target.value)} value={name} />
                                </div>
                            </div>
                            <div className="form-group mb-6">
                                <label className='text-white md:text-lg text-base tracking-wider ' htmlFor="exampleInput123">Email Address</label>
                                <div className='relative'>
                                <input type="email" className="input-feild" id="exampleInput125"
                                    readOnly value={profile?.data?.email} />
                                    {
                                  false    ?  <button className='absolute right-0 h-full px-8 py-2 top-0 bg-[#FA5252] text-white '>send email verification </button> : <button className='absolute right-0 h-full px-2 py-2 top-0 bg-[#FA5252] text-white flex justify-center items-center  '><img className='w-10 h-10' src={badge} alt="" /></button>
                                    }
                               
                                </div>
                                
                            </div>
                            <div className="form-group mb-6">
                                <label className='text-white md:text-lg text-base tracking-wider ' htmlFor="exampleInput123">Mobile Number:</label>
                           
                                <input type="number" className="input-feild" id="exampleInput126"
                                    readOnly value={profile?.data?.mobile} />
                                  
                             
                            </div>
                            <div className="form-group mb-6">
                                <label className='text-white md:text-lg text-base tracking-wider ' htmlFor="exampleInput123">User Photo:</label>
                                <input onChange={handlePhoto} type="file" className="input-feild" id="exampleInput126" />
                            </div>
                            <div className="form-group mb-6">
                                <label className='text-white md:text-lg text-base tracking-wider ' htmlFor="exampleInput123">Favorite Game:</label>
                                <input type="text" className="input-feild" id="exampleInput126"
                                    onChange={(e) => setFavorite(e.target.value)} value={favorite} />
                            </div>
                            <button type='submit' className="relative inline-flex items-center px-12 py-3 overflow-hidden md:text-lg text-base font-medium text-white border-2 border-white hover:border-[#DD2476] rounded-full hover:text-white group hover:bg-gray-50">
                                <span className="absolute left-0 block w-full h-0 transition-all bg-gradient-to-r from-[#FA5252] to-[#DD2476] opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                                <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                </span>
                                <span className="relative">Update Profile</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default EditProfile;