import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import "./App.css";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import Games from "./components/Games";
import Lottery from "./components/Lottery";
import Login from "./components/Auth/Login";
import History from "./components/profile/History";
import Orders from "./components/profile/Orders";
import Wallet from "./components/profile/Wallet";
import EditProfile from "./components/profile/EditProfile";
import Review from "./components/profile/Review";
import Recharge from "./components/profile/Recharge";
import Payment from "./components/profile/Payment";
import SingleProduct from "./components/SingleProduct";
import PrivateRoute from "./components/profile/PrivateRoute";
import Blog from "./components/Blog";
import ScrollToTop from "./components/ScrollToTop";
import axios from "axios";
import UseData from "./components/Hooks/UseData";
import Invoice from "./components/Invoice";
import MobileRecharge from "./components/profile/MobileRecharge";
import Offer from "./components/Offer";
import Faq from "./components/Faq";
import BlogDetails from "./components/BlogDetails";
import Swal from "sweetalert2";
import GiftOffer from "./components/GiftOffer";
import SubscriptionsOffer from "./components/SubscriptionsOffer";
import { HelmetProvider } from "react-helmet-async";
import Register from "./components/Auth/Register";
import OrderConfirmation from "./components/Order/confirmation";
import SingleVoucher from "./components/SingleVoucher";
import VoucherOrder from "./components/profile/VoucherOrder";
import ForgotPassword from "./components/Auth/ForgotPassword";

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.post["Accept"] = "application/json";
  const { token } = UseData();

  axios.defaults.headers.common["Authorization"] = `Bearer ${token?.api_token}`;
  axios.defaults.withCredentials = true;

  window?.axios?.interceptors?.response?.use(
    function (response) {
      return response;
    },
    function (error) {
      if (401 === error?.response?.status) {
        Swal(
          {
            title: "Session Expired",
            text: "Your session has expired. Would you like to be redirected to the login page?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes",
            closeOnConfirm: false,
          },
          function () {
            window.location = "/login";
          }
        );
      } else {
        return Promise.reject(error);
      }
    }
  );
  const helmetContext = {};

  return (
    <>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <ScrollToTop />
          <div className="fixed-footer">
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/games" element={<Games />} />
              <Route path="/lottery" element={<Lottery />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/faq" element={<Faq />} />
              <Route
                path="/history"
                element={
                  <PrivateRoute>
                    <History />
                  </PrivateRoute>
                }
              />
              <Route
                path="/orders"
                element={
                  <PrivateRoute>
                    <Orders />
                  </PrivateRoute>
                }
              />

              <Route
                path="/voucher-orders"
                element={
                  <PrivateRoute>
                    <VoucherOrder />
                  </PrivateRoute>
                }
              />

              <Route
                path="/wallet"
                element={
                  <PrivateRoute>
                    <Wallet />
                  </PrivateRoute>
                }
              />
              <Route
                path="/profile"
                element={
                  <PrivateRoute>
                    <EditProfile />
                  </PrivateRoute>
                }
              />
              <Route path="/review" element={<Review />} />
              <Route
                path="/payment"
                element={
                  <PrivateRoute>
                    <Recharge />
                  </PrivateRoute>
                }
              />
              <Route
                path="/order-details/:orderId"
                element={
                  <PrivateRoute>
                    <OrderConfirmation />
                  </PrivateRoute>
                }
              />
              <Route
                path="/order/payment"
                element={
                  <PrivateRoute>
                    <Payment />
                  </PrivateRoute>
                }
              />
              <Route
                path="/invoice"
                element={
                  <PrivateRoute>
                    <Invoice />
                  </PrivateRoute>
                }
              />
              <Route
                path="/mobileRecharge"
                element={
                  <PrivateRoute>
                    <MobileRecharge />
                  </PrivateRoute>
                }
              />
              <Route path="/product/:productName" element={<SingleProduct />} />
              <Route
                path="/voucher-product/:productName"
                element={<SingleVoucher />}
              />

              <Route path="/offer/:productName" element={<Offer />} />
              <Route path="/gift/:productName" element={<GiftOffer />} />
              <Route
                path="/subscriptions/:productName"
                element={<SubscriptionsOffer />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:id" element={<BlogDetails />} />
            </Routes>
            <Footer />
          </div>
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}

export default App;
