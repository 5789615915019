import React from 'react';
import { Link } from 'react-router-dom';


const Product = ({ product }) => {

    return (
        <>
            
            <div className="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2  ">
                <Link to={`/product/${product?.slug}`} className="rounded-md ">
                    <div className="relative rounded-md bg-[#111111]  time ">
                        <span className="absolute z-10 left-2 top-[9px] px-6 font-normal rounded-sm pt-[4px] pb-[3px] bg-[#e12b70] text-white  ">
                            SALE
                        </span>
                        <div className="overflow-hidden rounded-md border border-[#c07e00] hover:border-[#e12b70] ">
                            <img
                                src={
                                    process.env.REACT_APP_BACKEND_IMAGE_URL +
                                    product?.photo
                                }
                                alt="PUBGM (GLOBAL)"
                                className="rounded-t-md transform time hover:scale-125 "
                            />
                        </div>
                        <div className="text-center pt-4">
                            <h5 className="md:text-[20px] text-[18px] text-white font-medium pb-3 ">
                                {product?.name}
                            </h5>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
};

export default Product;