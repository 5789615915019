import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import hr from "../assets/hr1.png"
import PageTitle from './PageTitle';
const pars = require('html-react-parser')

const BlogDetails = () => {
    const [blog, setBlog] = useState({})
    const { id } = useParams()
    useEffect(() => {
        axios.get(`/api/blog/${id}`).then(dt => {
            setBlog(dt?.data?.data);
        })
    }, [id])

    return (
        <>
         <PageTitle title={blog?.post?.title} description={blog?.post?.blog_body}></PageTitle>
        <div className='container py-10'>
            <div className="grid grid-cols-12 gap-10">
                <div className="col-span-12 md:col-span-7">
                            <div>
                                <div className='relative'><img className='  w-full ' src={blog?.post?.photo} alt="" />
                                    <span className='w-[95%] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute block h-[95%]  border border-[#f54a58]'></span>
                                </div>
                                <div className='mt-10'>
                                    <h2 className='text-white text-[35px] '>{blog?.post?.title}</h2>
                                    {
                                        blog?.post?.description &&  <p className='text-gray-200 text-lg'>{pars(JSON.parse(blog?.post?.description))}</p>
                                    }
                                </div>
                            </div>
                </div>
                <div className="col-span-12 md:col-span-5 ">
                    <div>
                        <h3 className='text-white text-[35px]'>Recent posts</h3>
                        <img className='mb-8' src={hr} alt="" />
                        {
                            blog?.recent_posts?.map((item) => (
                                <Link to={`/blog/${item?.slug}`}>
                                <div className='grid grid-cols-12 gap-6 mb-6'>
                                    <div className='relative group  col-span-3  cursor-pointer '>
                                        <img className='w-full time transform group-hover:-translate-x-2 group-hover:-translate-y-2  ' src={item?.photo} alt="" />
                                        <span className='w-[95%] top-1/2 left-1/2 transform -translate-x-1/2 group-hover:z-[-1] -translate-y-1/2 absolute block h-[95%]  border border-[#f54a58]'></span>
                                    </div>

                                    <h3 className='text-white col-span-9 text-lg '>{item?.title}</h3>
                                </div>
                                </Link>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default BlogDetails;