// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// const firebaseConfig = {
//   apiKey: "AIzaSyBcmf7YeSfCT-rJ7h1t5pibLrXi5kpyw8o",
//   authDomain:"fnzgame.firebaseapp.com",
//   databaseURL:"https://fnzgame-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "fnzgame",
//   storageBucket: "fnzgame.appspot.com",
//   messagingSenderId: "438436582480",
//   appId: "1:438436582480:web:f894a49fe8b3d88a056d55",
// };
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATA_BASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;


