import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import hr from "../assets/hr1.png";
import { Link } from "react-router-dom";

const FollowUs = () => {
  return (
    <>
      <div className="container py-10">
        <h2 className="text-[25px] md:text-[35px] text-white">FOLLOW US</h2>
        <img className="w-[200px] -mt-2 mb-6 " src={hr} alt="" />
        <div
          className="mx-auto sm:px-6 lg:px-8"
          style={{ maxWidth: "850px", margin: "auto" }}
        >
          <div className="w-full sm:rounded-lg shadow-md follow-us">
            <div className="">
              <div className="grid  md:gap-4 gap-2 my-2 md:my-5">
                <div className="bg-[#222222] h-full p-4 rounded-lg">
                  <div className="grid md:grid-cols-2 grid-cols-1 md:gap-4 gap-2 my-2 md:my-5">
                  <Link
                      to="https://www.facebook.com/fnzgamingshop1"
                      className="flex items-center gosizi-single-support rounded px-5 pb-2.5 pt-4 overflow-hidden group bg-white text-black relative hover:bg-gradient-to-r from-[#FA5252] to-[#DD2476]  hover:ring-offset-2  transition-all ease-out duration-300 hover:text-white"
                      target="_blank"
                    >
                     <i
                        class="bg-[#316FF6] hover:bg-[#dcb725] relative rounded-full justify-center items-center fa fa-facebook md:text-2xl text-white fa fa-times md:w-14 md:h-12 w-10 h-10 flex"
                        aria-hidden="true"
                      ></i>
                      <h6
                        style={{ width: "88%" }}
                        className="text-[16px] ml-2"
                      >
                        নতুন নতুন সব অফার পেতে আমাদের ফেইসবুক পেজ Folllow করুন। 
                      </h6>
                    </Link>
                    <Link
                      to="https://www.youtube.com/@fnzgaming7182"
                      className="flex items-center gosizi-single-support rounded px-5 pb-2.5 pt-4 overflow-hidden group bg-white text-black relative hover:bg-gradient-to-r from-[#FA5252] to-[#DD2476]  hover:ring-offset-2  transition-all ease-out duration-300 hover:text-white"
                      target="_blank"
                    >
                     <i
                        class="bg-[#CD201F] hover:bg-[#dcb725] relative rounded-full justify-center items-center fa fa-youtube md:text-2xl text-white fa fa-times md:w-14 md:h-12 w-10 h-10 flex"
                        aria-hidden="true"
                      ></i>
                      <h6
                        style={{ width: "88%" }}
                        className="text-[16px] ml-2"
                      >
                         ফ্রি ডায়মন্ড জিতার অফারটিতে অংশ গ্রহন করতে আমাদের চ্যানেলটি সাবস্ক্রাইব করুন ।
                      </h6>
                    </Link>
                    <Link
                      to="https://chat.whatsapp.com/I0stDPY7DzuL5zU4HQBl6j"
                      className="flex items-center gosizi-single-support rounded px-5 pb-2.5 pt-4 overflow-hidden group bg-white text-black relative hover:bg-gradient-to-r from-[#FA5252] to-[#DD2476]  hover:ring-offset-2  transition-all ease-out duration-300 hover:text-white"
                      target="_blank"
                    >
                      <i
                        class="bg-[#1cbe43] hover:bg-[#dcb725] relative rounded-full justify-center items-center fa fa-whatsapp md:text-2xl text-white fa fa-times md:w-14 md:h-12 w-10 h-10 flex"
                        aria-hidden="true"
                      ></i>
                      <h6
                        style={{ width: "88%" }}
                        className="text-[16px] ml-2"
                      >
                         যেকোনো সমস্যায় পড়লে আমাদের হোয়াটসঅ্যাপে লাইভ চ্যাট এ কথা বলুন। 
                      </h6>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FollowUs;
