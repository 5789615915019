import React from "react";
import Products from "./Products";
import PageTitle from "./PageTitle";

const Games = () => {
  return (
    <>
      <PageTitle
        title="Games - FNZ Gaming Shop"
        description="Page Description"
      ></PageTitle>
      <Products name="PRODUCT" />
    </>
  );
};

export default Games;
