import  { useEffect, useState } from 'react';
import hr from "../assets/hr1.png"
import axios from 'axios';

const ListOrder = () => {
    const [order, setOrder] = useState([])

    useEffect(() => {
        axios.get("/api/latest/orders").then(dt => {
            setOrder(dt?.data?.data);
        })
    }, [])
    return (
        <div className='container'>
            <h2 className='text-[25px] md:text-[35px] text-white '>LAST COMPLETED ORDER</h2>
            <img className=' w-[250px] -mt-2 mb-6' src={hr} alt="" />
            <div className="relative overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase  bg-[#222222]">
                        <tr className=' border-b border-[#c07e00]'>
                            <th scope="col" className="md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap">
                                #
                            </th>
                            <th scope="col" className="md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap">
                                Name
                            </th>
                            <th scope="col" className="md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap">
                                Product
                            </th>
                            <th scope="col" className="md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap">
                                Date
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            order?.map((item, i) => <tr key={i} className="bg-[#222222] border-b border-[#c07e00]">
                                <th scope="row" className="md:px-6 py-4 px-1 font-medium md:text-[20px] text-[16px] text-white whitespace-nowrap">
                                  {i+1}  
                                </th>
                                <td className="md:px-6 py-4 px-1 font-medium md:text-[20px] text-[16px] text-white whitespace-nowrap">
                                    {item?.user?.name}
                                </td>
                                <td className=" md:px-6 py-4 px-1 font-medium md:text-[20px] text-[16px] text-white whitespace-nowrap">
                                    {item?.product}
                                </td>
                                <td className="md:px-6 py-4 px-1 font-medium md:text-[20px] text-[16px] text-white whitespace-nowrap">
                                    {item?.created_at}
                                </td>
                            </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ListOrder;