import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import UseData from "../Hooks/UseData";
import { ThreeDots } from "react-loader-spinner";

import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import PageTitle from "../PageTitle";
const parse = require("html-react-parser");
const Recharge = () => {
  const { profile } = UseData();
  const history = useNavigate();
  const [type, setType] = useState([]);
  const [info, setInfo] = useState({});
  const [tranid, setTranid] = useState("");
  const [mobile, setMobile] = useState("");

  const order = JSON.parse(localStorage.getItem("order"));
  useEffect(() => {
    axios.get("/api/payment-methods").then((dt) => {
      setType(dt?.data?.data);
    });
  }, []);

  const handlePayment = (payload) => {
    const localStorageData = JSON.parse(localStorage.getItem("order"));
    const formData = new FormData();
    for (const key in localStorageData) {
      if (localStorageData.hasOwnProperty(key)) {
        formData.append(key, localStorageData[key]);
      }
    }

    formData.append("payment_id", payload);

    axios.post(`/api/place-new-order`, formData).then((dt) => {
      if (dt?.data?.status) {
        console.log(dt?.data?.data)
        window.location.replace(dt?.data?.data);
      } else {
        history("/");
        toast.error(dt?.data?.message??"Something Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const localStorageData = JSON.parse(localStorage.getItem("order"));
    const formData = new FormData();
    for (const key in localStorageData) {
      if (localStorageData.hasOwnProperty(key)) {
        formData.append(key, localStorageData[key]);
      }
    }

    formData.append("transaction_id", tranid);
    formData.append("sender_no", mobile);
    formData.append("payment_id", info?.data?.id);
    formData.append("payment_method", info?.data?.slug);

    axios.post(`/api/payment-submit`, formData).then((dt) => {
      if (dt?.data?.status) {
        // order place redrice
        toast.info("Order Place Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        history("/orders");
      } else {
        toast.error("Something Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  };

  const handleWallet = (e) => {
    e.preventDefault();
    const localStorageData = JSON.parse(localStorage.getItem("order"));
    const formData = new FormData();
    for (const key in localStorageData) {
      if (localStorageData.hasOwnProperty(key)) {
        formData.append(key, localStorageData[key]);
      }
    }
    formData.append("payment_id", info?.data?.id);
    formData.append("payment_method", info?.data?.slug);
    axios.post(`/api/payment-submit`, formData).then((dt) => {
      if (dt?.data?.status) {
        // order place redrice
        history("/orders");
        toast.info("Order Place Successfully!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error("Something Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  };

  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = (e) => {
    // e.preventDefault();
    toast.info("Copied Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    setIsCopied(true);
  };

  return (
    <>
      <PageTitle
        title="Recharge - FNZ Gaming Shop"
        description="Page Description"
      ></PageTitle>
      {info.status && info?.data?.name !== "Wallet" ? (
        <div className="absolute pb-10   left-0 top-0 w-[100vw] h-[100vh]  z-[1000] bg-[#111111] ">
          <div className="container h-full flex justify-center  items-center md:py-10">
            <div className="md:w-4/6 lg:w-3/6 w-full bg-[#222222]  py-6 mx-auto overflow-my overflow-my pb-20 mt-80 ">
              <img
                className="h-28 text-center mx-auto mt-5"
                src={`https://backend.fnzgamingshop.com${info?.data?.logo}`}
                alt=""
              />

              {info?.data?.name === "Rocket" && (
                <h4 className="text-gray-100 md:text-2xl mb-2 text-xl text-center">
                  রকেট দিয়ে সেন্ড মানি করুন
                </h4>
              )}

              {info?.data?.name === "Bkash" && (
                <h4 className="text-gray-100 md:text-2xl mb-2 text-xl text-center">
                  বিকাশ দিয়ে সেন্ড মানি করুন
                </h4>
              )}
              {info?.data?.name === "Nagad" && (
                <h4 className="text-gray-100 md:text-2xl mb-2 text-xl text-center">
                  নগদ দিয়ে সেন্ড মানি করুন
                </h4>
              )}

              {info?.data?.name === "Wallet" && (
                <h4 className="text-gray-100 md:text-2xl mb-2 text-xl text-center">
                  ওয়ালেট দিয়ে সেন্ড মানি করুন
                </h4>
              )}

              <span className="h-[6px] w-full block bg-[#fd648b]"></span>
              <h3 className="flex text-white items-center justify-center md:text-[35px] text-[25px] py-3 pl-6  ">
                © FNZ GAMING SHOP
              </h3>
              <div className="px-6">
                <CopyToClipboard text={info?.data?.mobile} onCopy={onCopyText}>
                  <div className="relative w-full my-8 inline-flex items-center px-6 md:px-12 py-3 overflow-hidden md:text-lg text-base font-medium  border-2 border-white text-white rounded-full hover:text-white group ">
                    <span className="absolute left-0 block w-full h-0 transition-all  opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                    <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        ></path>
                      </svg>
                    </span>
                    {/* <span className="relative">Send Money No: 01838431708 {isCopied? <p className='text-lime-700 inline-block pl-3'>Copied</p> :""} </span> */}
                    <span className="relative">
                      Send Money No: {info?.data?.mobile}{" "}
                      {isCopied ? (
                        <div className="bg-[#fd648b] inline-block px-4 md:px-8 py-2 text-white ml-3 rounded-xl ">
                          Copied
                        </div>
                      ) : (
                        <div className="bg-[#fd648b] inline-block px-4 md:px-8 py-2 text-white ml-3 rounded-xl">
                          Copy
                        </div>
                      )}{" "}
                    </span>
                  </div>
                </CopyToClipboard>
                <form onSubmit={handleSubmit}>
                  <div className=" gap-4"></div>
                  <input type="hidden" name="" value={info?.data?.id} />
                  <input type="hidden" name="" value={info?.data?.slug} />
                  <div className="form-group mb-6">
                    <label
                      className="text-white md:text-lg text-base tracking-wider "
                      htmlFor="exampleInput123"
                    >
                      {info?.data?.mobile_field_label}
                    </label>
                    <input
                      onChange={(e) => setMobile(e.target.value)}
                      pattern={info?.data?.mobile_field_pattern}
                      type={info?.data?.mobile_field_type}
                      maxLength={info?.data?.mobile_field_max_length}
                      minLength={info?.data?.mobile_field_min_length}
                      className="form-control input-2"
                      id="exampleInput125"
                      placeholder={info?.data?.mobile_field_placeholder}
                      required
                    />
                  </div>
                  <div className="form-group mb-6">
                    <label
                      className="text-white md:text-lg text-base tracking-wider "
                      htmlFor="exampleInput123"
                    >
                      {info?.data?.tranid_field_label}
                    </label>
                    <input
                      onChange={(e) => setTranid(e.target.value)}
                      type="text"
                      pattern={info?.data?.tranid_field_pattern}
                      maxLength={info?.data?.tranid_field_max_length}
                      minLength={info?.data?.tranid_field_min_length}
                      className="form-control input-2"
                      id="exampleInput126"
                      placeholder={info?.data?.tranid_field_placeholder}
                      required
                    />
                  </div>
                  <h2 className="text-[30px] md:text-[35px] text-white  py-2 ">
                    Payment Process{" "}
                  </h2>
                  <ul className="list-disc list-inside text-white">
                    {parse(info?.data?.description)}
                  </ul>
                  <button className="relative mt-2 inline-flex items-center px-12 py-3 overflow-hidden md:text-lg text-base font-medium text-[#fd648b] border-2 border-[#fd648b] rounded-full hover:text-white group hover:bg-gray-50">
                    <span className="absolute left-0 block w-full h-0 transition-all bg-[#fd648b] opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                    <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        ></path>
                      </svg>
                    </span>
                    <span className="relative">Submit</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="absolute   left-0 top-0 w-[100vw] min-h-[100vh] overflow-y-auto overflow-my  z-[1000000] bg-[#111111] ">
          <div className="container h-full flex justify-center items-center md:py-10 overflow-my">
            <div className="md:w-4/6 lg:w-3/6 w-full bg-[#222222]  py-6 mx-auto overflow-my overflow-y-auto  ">
              {info?.data?.logo ? (
                <img
                  className="h-20 text-center mx-auto mb-6"
                  src={`https://backend.fnzgamingshop.com${info?.data?.logo}`}
                  alt=""
                />
              ) : (
                <h2 className="text-center text-[35px] pb-2 text-white">
                  Select Payment Method
                </h2>
              )}
              <span className="h-[6px] w-full block bg-[#fd648b]"></span>
              <div className="flex justify-center gap-8">
                {type.length > 0 ? (
                  type?.map((item) => (
                    <div
                      className={`${
                        info.status
                          ? "hidden"
                          : "flex items-center flex-col p-1"
                      }`}
                      onClick={() => handlePayment(item?.id)}
                    >
                      <img
                        className="md:h-32 h-20 mt-2 cursor-pointer"
                        src={`https://backend.fnzgamingshop.com${item?.logo}`}
                        alt=""
                      />
                      {item.name === "Nagad" && (
                        <h4 className="text-gray-100 md:text-2xl mb-2 mt-1 text-xl text-center">
                          নগদ
                        </h4>
                      )}
                      {item.name === "Bkash" && (
                        <h4 className="text-gray-100 md:text-2xl mb-2 mt-1 text-xl text-center">
                          বিকাশ
                        </h4>
                      )}
                      {item.name === "Rocket" && (
                        <h4 className="text-gray-100 md:text-2xl mb-2 mt-1 text-xl text-center">
                          রকেট
                        </h4>
                      )}
                      {item.name === "Wallet" && (
                        <h4 className="text-gray-100 md:text-2xl mb-2 mt-1 text-xl text-center">
                          ওয়ালেট
                        </h4>
                      )}

                      {item.slug === "gateway" && (
                        <h4 className="text-gray-100 md:text-2xl mb-2 mt-1 text-xl text-center">
                          {item?.name}
                        </h4>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="w-full h-[100%] bg-black flex justify-center items-center ">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                )}
                {info?.status && info?.data?.name === "Wallet" && (
                  <div
                    className={`${
                      info?.data?.name === "Wallet" && info?.status
                        ? "block"
                        : "hidden"
                    } overflow-my container h-full flex justify-center items-center md:pt-6 rounded-lg mb-10 overflow-my `}
                  >
                    <div className=" w-full bg-[#222222]  mx-auto overflow-my ">
                      <div className="flex justify-center gap-8 w-full ">
                        <div className="flex justify-center w-full items-center ">
                          <h3 className="text-center md:text-[35px] text-[25px] mb-4 text-white">
                            © FNZ GAMING SHOP
                          </h3>
                        </div>
                      </div>
                      <table className="w-full ">
                        <tbody>
                          <tr className="border border-[#fd648b]   text-white text-lg  pt-6">
                            <td className="py-3 pl-3 border-r border-[#fd648b]">
                              Product Name
                            </td>
                            <td className="py-3 pr-3 text-right">
                              {order?.name}
                            </td>
                          </tr>
                          <tr className="border-b border-[#fd648b]  py-5 text-white text-lg ">
                            <td className="py-3 pl-3 border-r border-[#fd648b] border-l">
                              Product Price
                            </td>
                            <td className="py-3 pr-3 text-right border-r border-[#fd648b]">
                              BDT {order?.amount} //=
                            </td>
                          </tr>
                          <tr className="border-b border-[#fd648b]  py-5 text-white text-lg ">
                            <td className="py-3 pl-3 border-r border-[#fd648b] border-l">
                              Wallet Balance
                            </td>
                            <td className="py-3 pr-3 text-right border-r border-[#fd648b]">
                              BDT {profile?.data?.wallet} //=
                            </td>
                          </tr>
                          <tr className="border-b border-[#fd648b]  py-5 text-white text-lg ">
                            <td className="py-3 pl-3 border-r border-[#fd648b] border-l">
                              Avilable Balance
                            </td>
                            <td className="py-3 pr-3 text-right border-r border-[#fd648b]">
                              BDT {profile?.data?.wallet - order?.amount} //=
                            </td>
                          </tr>
                          {profile?.data?.wallet - order?.amount < 0 && (
                            <div className="text-gray-100 mt-2">
                              আপনার ওয়ালেটে পর্যাপ্ত ব্যালেন্স নেই। অনুগ্রহ করে
                              রিচার্জ করুন
                            </div>
                          )}
                        </tbody>
                      </table>
                      <button
                        onClick={handleWallet}
                        className="relative mt-6 inline-flex items-center px-12 py-3 overflow-hidden md:text-lg text-base font-medium text-[#fd648b] border-2 border-[#fd648b] rounded-full hover:text-white group hover:bg-gray-50"
                      >
                        <span className="absolute left-0 block w-full h-0 transition-all bg-[#fd648b] opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                        <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                          <svg
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M14 5l7 7m0 0l-7 7m7-7H3"
                            ></path>
                          </svg>
                        </span>
                        <span className="relative">Submit</span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Recharge;
