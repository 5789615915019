import React, { useEffect, useRef } from "react";
import hr from "../../assets/hr1.png";
import $ from "jquery";
import "datatables.net-responsive";
import PageTitle from "../PageTitle";
import axios from 'axios';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
const parse = require("html-react-parser");
const Orders = () => {
  $.DataTable = require("datatables.net");
  const tableRef2 = useRef();
  const token =
    localStorage.getItem("userToken") &&
    JSON.parse(localStorage.getItem("userToken"));
  const base_url = process.env.REACT_APP_BACKEND_BASE_URL;
  const page = 1;

  const url = window.location.search;
  const history = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(url);
    const invoice_id = params.get("invoice_id");

    if (invoice_id) {
      axios
        .post("/api/verify-payment", {
          invoice_id: invoice_id,
        })
        .then((response) => {
          if(response?.data?.data?.payment_status === "Paid" && response?.data?.data?.order_status === "Processing"){
            history("/orders");
            toast.info("Order Place Successfully!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
             });
          }
          else if(response?.data?.data?.payment_status === "Unpaid" && response?.data?.data?.order_status === "Pending"){
            toast.info("Payment is Processing. Please wait..", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
             });
          }
          else{
            history("/orders");
            toast.error(response?.data?.message ?? 'Something Wrong', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
             });
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message ?? 'Something Wrong', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });

          console.error("Error verifying payment:", error);
        });
    }
  }, [url, history]);



  useEffect(() => {
    $(tableRef2.current)
      .dataTable({
        bDestroy: true,
      })
      .fnDestroy();
    var table = $(tableRef2.current).DataTable({
      responsive: true,
      processing: true,
      serverSide: true,
      paging: false,
      columnDefs: [{ responsivePriority: 1, targets: 4, width: 100 }],
      ajax: {
        url: `${base_url}api/user/orders?page=${page}`,
        dataType: "json",
        type: "get",

        beforeSend: function (xhr) {
          xhr.setRequestHeader("Authorization", `Bearer ${token?.api_token}`);
        },
      },

      columns: [
        {
          data: "DT_RowIndex",
          name: "DT_RowIndex",
          orderable: false,
          searchable: false,
        },
        { data: "created_at", name: "created_at" },
        { data: "queue", name: "queue"},
        { data: "order_no", name: "order_no" },
        { data: "product", name: "product" },
        { data: "amount", name: "amount" },
        { data: "order_status", name: "order_status" },
        
      ],
    });
    // Extra step to do extra clean-up.
    return function () {
      table.destroy();
    };
  }, []);

  return (
    <>
      <PageTitle
        title="Order History - FNZ Gaming Shop"
        description="Page Description"
      ></PageTitle>
      <div className="container py-8">
        <h2 className="text-[25px] md:text-[35px] text-white ">
          LATEST ORDERS
        </h2>
        <img className=" w-[200px] -mt-2 mb-6" src={hr} alt="" />
        <div className="relative overflow-x-hidden  bg-[#222222]">
          <table
            ref={tableRef2}
            className="display responsive nowrap  my-table w-full text-sm text-left  text-gray-500 dark:text-gray-400"
          >
            <thead className="text-xs text-gray-700 uppercase  ">
              <tr className=" border-b my border-[#c07e00]">
                <th
                  scope="col"
                  className="  md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap"
                >
                  #
                </th>

                <th
                  scope="col"
                  className=" md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap"
                >
                  Order Date
                </th>
                <th
                  scope="col"
                  className=" md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap"
                >
                  Queue
                </th>
                <th
                  scope="col"
                  className=" md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap"
                >
                  Order ID
                </th>
                <th
                  data-priority="1"
                  scope="col"
                  className=" all md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap"
                >
                  Product
                </th>
                <th
                  scope="col"
                  className="all md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="  md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap"
                >
                  Status
                </th>
                
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Orders;
