import React from "react";
import { Link, NavLink } from "react-router-dom";
// import moduleName from 'module';

const SIdeBar = ({ setOpen, open, profile, logout }) => {
   return (
      <div className="md:hidden block">
         <>
            {!open && (
               <div
                  className="text-3xl fixed right-4 top-3 z-[60]"
                  onClick={() => setOpen(!open)}
               >
                  <i className="fa-solid text-white fa-bars"></i>
               </div>
            )}

            <div
               className={`top-0 right-0 w-[300px] ease-in-out duration-300
               bg-[#222222]  text-white fixed h-full z-[100] ${
                  open ? "translate-x-0 " : "translate-x-full"
               }`}
            >
               <button
                  className="flex text-3xl font-serif font-semibold h-10 w-10 rounded-full text-gray-100 items-center cursor-pointer fixed right-[240px] top-6 z-[120]"
                  onClick={() => setOpen(!open)}
               >
                  <i className="fa-solid text-white fa-xmark"></i>
               </button>

               <div className="flex relative list-none -mt-24  flex-col justify-center items-center w-full h-full">
                  <div className="border-b hover:border-gray-400 duration-500 border-gray-100 w-full text-center mb-2">
                     <NavLink
                        className={({ isActive }) =>
                           isActive
                              ? "  text-[#DD2476] mt-7 text-[27px] hover:text-[[#DD2476]]"
                              : "text-white  mt-7 text-[27px] hover:text-[#DD2476] duration-500 "
                        }
                        to="/"
                     >
                        HOME
                     </NavLink>
                  </div>
                  <div className="border-b hover:border-gray-400 duration-500 border-gray-100 w-full text-center mb-2">
                     <NavLink
                        className={({ isActive }) =>
                           isActive
                              ? " text-[#DD2476] mt-7 text-[27px] hover:text-[[#DD2476]]"
                              : "text-white  mt-7 text-[27px] hover:text-[#DD2476] duration-500 "
                        }
                        to="games"
                     >
                        GAMES
                     </NavLink>
                  </div>
                  <div className="border-b hover:border-gray-400 duration-500 border-gray-100 w-full text-center mb-2">
                     <NavLink
                        className={({ isActive }) =>
                           isActive
                              ? " text-[#DD2476] mt-7 text-[27px] hover:text-[[#DD2476]]"
                              : "text-white  mt-7 text-[27px] hover:text-[#DD2476] duration-500 "
                        }
                        to="/lottery"
                     >
                        LUCKY DRAW
                     </NavLink>
                  </div>
                  <div className="border-b hover:border-gray-400 duration-500 border-gray-100 w-full text-center mb-2">
                     <NavLink
                        className={({ isActive }) =>
                           isActive
                              ? " text-[#DD2476] mt-7 text-[27px] hover:text-[[#DD2476]]"
                              : "text-white  mt-7 text-[27px] hover:text-[#DD2476] duration-500 "
                        }
                        to="/review"
                     >
                        REVIEW
                     </NavLink>
                  </div>
                  <div className="border-b hover:border-gray-400 duration-500 border-gray-100 w-full text-center mb-2">
                     <NavLink
                        className={({ isActive }) =>
                           isActive
                              ? " text-[#DD2476] mt-7 text-[27px] hover:text-[[#DD2476]]"
                              : "text-white  mt-7 text-[27px] hover:text-[#DD2476] duration-500 "
                        }
                        to="/blog"
                     >
                        BLOG
                     </NavLink>
                  </div>
                  <div className="border-b hover:border-gray-400 duration-500 border-gray-100 w-full text-center mb-2">
                     <NavLink
                        className={({ isActive }) =>
                           isActive
                              ? " text-[#DD2476] mt-7 text-[27px] hover:text-[[#DD2476]]"
                              : "text-white  mt-7 text-[27px] hover:text-[#DD2476] duration-500 "
                        }
                        to="/faq"
                     >
                        FAQ
                     </NavLink>
                  </div>
                  {profile?.api_token ? (
                     <div>
                        <button
                           onClick={logout}
                           className="text-3xl mt-4 relative  inline-flex items-center justify-start px-12 py-2 overflow-hidden font-semibold transition-all bg-[#ffffff] text-black rounded hover:bg-[#111111] group"
                        >
                           <span className="w-48   h-48 rounded rotate-[-40deg] bg-gradient-to-r from-[#FA5252] to-[#DD2476] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
                           <span className="relative w-full text-left text-black text-lg tracking-wider transition-colors duration-300 ease-in-out group-hover:text-white">
                              LOGOUT
                           </span>
                        </button>
                     </div>
                  ) : (
                     <li>
                        <Link
                           to="/login"
                           className="text-3xl  relative  inline-flex items-center justify-start px-12 py-2 overflow-hidden font-semibold transition-all bg-[#ffffff] text-black rounded hover:bg-[#111111] group"
                        >
                           <span className="w-48  h-48 rounded rotate-[-40deg] bg-gradient-to-r from-[#FA5252] to-[#DD2476] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
                           <span className="relative w-full text-left text-black text-lg tracking-wider transition-colors duration-300 ease-in-out group-hover:text-white">
                              LOGIN
                           </span>
                        </Link>
                     </li>
                  )}
               </div>
            </div>
         </>
      </div>
   );
};

export default SIdeBar;
