import React, { useState } from 'react';
import logo from "../../assets/logo.png"
import bkash from "../../assets/bkash.png"
import PageTitle from '../PageTitle';


const Payment = () => {
    return (
        <>
                <PageTitle title="Payment - FNZ Gaming Shop" description="Page Description"></PageTitle>
        <div className='absolute   left-0 top-0 w-[100vw] h-[100vh] z-[1000000] bg-[#111111] '>
            <div className='container h-full flex justify-center items-center md:py-10'>
                <div className='md:w-4/6 lg:w-3/6 w-full bg-[#222222]  py-6 mx-auto '>
                    <img className='h-20 text-center mx-auto mb-6 ' src={bkash} alt="" />
                    <span className='h-[6px] w-full block bg-[#fd648b]'></span>
                    <span className='flex text-white items-center py-3 pl-6  '> <img className='h-10 text-center pr-3 ' src={logo} alt="" />FNZ Gaming Shop.</span>
                    <div className='px-6'>
                        <form>
                            <div className=" gap-4">
                            </div>
                            <div className="form-group mb-6">
                                <label className='text-white md:text-lg text-base tracking-wider ' htmlFor="exampleInput123">Enter Sender Mobile No</label>
                                <input type="email" className="form-control input-2" id="exampleInput125"
                                    placeholder="Enter Sender Mobile No" />
                            </div>
                            <div className="form-group mb-6">
                                <label className='text-white md:text-lg text-base tracking-wider ' htmlFor="exampleInput123">Enter Bkash Transaction Id:</label>
                                <input type="number" className="form-control input-2" id="exampleInput126"
                                    placeholder="Enter Bkash Transaction Id" />
                            </div>
                            <button className="relative w-full inline-flex items-center px-12 py-3 overflow-hidden md:text-lg text-base font-medium text-indigo-600 border-2 border-indigo-600 rounded-full hover:text-white group hover:bg-gray-50">
                                <span className="absolute left-0 block w-full h-0 transition-all bg-indigo-600 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                                <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                </span>
                                <span className="relative">Send Money No: 01838431708</span>
                            </button>
                            <h2 className='text-[30px] md:text-[35px] text-white  py-2 '>Payment Process </h2>
                            <ul className='list-disc list-inside'>
                                <li className='text-white'>*247# ডায়াল করে আপনার বিকাশ মোবাইল মেনুতে যান অথবা বিকাশ অ্যাপে যান।</li>
                                <li className='text-white'>"Send Money" -এ ক্লিক করুন।</li>
                                <li className='text-white'>প্রাপক নম্বর হিসেবে এই নম্বরটি লিখুনঃ
                                    01838431708</li>
                                <li className='text-white'>টাকার পরিমাণঃ লিখুন</li>
                                <li className='text-white'>এখন নিশ্চিত করতে আপনার বিকাশ পিন লিখুন।</li>
                                <li className='text-white'>সবকিছু ঠিক থাকলে, আপনি বিকাশ থেকে একটি নিশ্চিতকরণ বার্তা পাবেন।</li>
                                <li className='text-white'>এখন উপরের বক্সে আপনার "Transaction ID" এবং "Mobile No" দিন এবং নিচের "SUBMIT" বাটনে ক্লিক করুন।</li>
                            </ul>
                            <button className="relative mt-2 inline-flex items-center px-12 py-3 overflow-hidden md:text-lg text-base font-medium text-indigo-600 border-2 border-indigo-600 rounded-full hover:text-white group hover:bg-gray-50">
                                <span className="absolute left-0 block w-full h-0 transition-all bg-indigo-600 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                                <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                </span>
                                <span className="relative">Submit</span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Payment;