import hr from "../assets/hr1.png";
import UseData from "./Hooks/UseData";
import Product from "./Product";
import VoucherProduct from "./VoucherProduct";

const Products = ({ name }) => {
  const { products } = UseData();
  console.log(name);
  return (
    <>
      <div className="container pb-4 pt-10">
        {name === "POPULAR GAMES" ? (
          <>
            <h2
              className={`${
                name === "POPULAR GAMES"
                  ? "text-[25px] md:text-[35px]"
                  : "text-[30px] md:text-[50px]"
              } text-white `}
            >
              {name}
            </h2>
            <img
              className={`${
                name === "POPULAR GAMES" ? "w-[250px]" : " w-[200px]"
              }  -mt-2 mb-6 `}
              src={hr}
              alt=""
            />
          </>
        ) : (
          <>
            {" "}
            <h2 className="text-[30px] md:text-[50px] text-white md:pt-10  ">
              {name}
            </h2>
            <img
              className={`${
                name === "PRODUCT"
                  ? "w-[200px] -mt-2 mb-6"
                  : " w-[250px] -mt-2 mb-2"
              }`}
              src={hr}
              alt=""
            />
            {/* <h3 className="md:text-[20px] text-white text-[18px] font-medium pb-4 ">
              HOME / {name}
            </h3> */}
          </>
        )}
        <div className="grid grid-cols-12 gap-4 md:gap-6 ">
          {products?.vouchers?.map((product) => (
            <VoucherProduct key={product?.id} product={product} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Products;
