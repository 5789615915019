import EndProduct from './EndProduct';
import hr from "../assets/hr1.png"
import UseData from './Hooks/UseData';
import { Link } from 'react-router-dom';

const Subscriptions = ({ name }) => {
    const { products } = UseData()

    return (
        <>
            <div className='container pb-4 pt-4'>
                <h2 className="text-[25px] md:text-[35px] text-white uppercase" >{name}</h2>
                <img className='w-[200px] -mt-2 mb-6 ' src={hr} alt="" />
                <div className="grid grid-cols-12 gap-4 md:gap-6 mt-6 ">
                    {
                        products?.subscriptions?.map((product) =>   <div key={product?.id} className="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2  ">
                        <Link to={`/subscriptions/${product?.slug}`} className="rounded-md ">
                            <div className="relative rounded-md bg-[#111111] ">
               
                                <div className='overflow-hidden rounded-md border  time border-[#c07e00] hover:border-[#e12b70] '>
                                    <img src={product?.photo} alt="PUBGM (GLOBAL)" className="rounded-t-md transform time hover:scale-125 " />
                                </div>
                                <div className="text-center pt-4">
                                    <h5 className="md:text-[20px] text-[18px] text-white font-medium pb-3 ">
                                        {product?.title}
                                    </h5>
                                </div>
                            </div>
                        </Link>
                    </div>)
                    }
                </div>
            </div>
        </>
    );
};

export default Subscriptions;