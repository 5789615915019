import {
   Accordion,
   AccordionBody,
   AccordionHeader,
} from "@material-tailwind/react";
import React, { useState } from "react";
import hr from "../assets/hr1.png";
import FAQ from "../assets/faq.png";
import { useEffect } from "react";
import axios from "axios";
import PageTitle from "./PageTitle";
const pars = require("html-react-parser");

const Faq = () => {
   const [open, setOpen] = useState();
   const [faq, setFaq] = useState([]);
   const [general, setGeneral] = useState([]);

   useEffect(() => {
      axios.get("/api/faq").then((dt) => {
         setFaq(dt?.data?.data);
         setGeneral(dt?.data?.extra);
      });
   }, []);

   const handleOpen = (value) => {
      setOpen(open === value ? 0 : value);
   };

   return (
      <>
         <PageTitle
            title="FAQ - FNZ Gaming Shop"
            description="Page Description"
         ></PageTitle>

         <div className="container mb-8 md:w-[70%] mx-auto">
            <img className="text-center block mx-auto " src={FAQ} alt="" />
            <div className="grid grid-cols-12 gap-6">
               <div className="col-span-12">
                  <h2 className="text-[35px] md:text-[40px] text-white pt-10 text-center  ">
                     General Questions
                  </h2>

                  <img
                     className=" w-[200px] mx-auto -mt-2 mb-10"
                     src={hr}
                     alt=""
                  />
                  <div className="rounded-md bg-[#222222] ">
                     {general[0]?.faqs?.map((item, i) => (
                        <Accordion
                           key={i}
                           className=""
                           open={open === item?.id}
                        >
                           <AccordionHeader
                              className={`${
                                 open === item?.id ? "bg-[#c07e00]" : ""
                              } text-white border-b text-[14px]  hover:bg-[#c07e00] px-3 border-[#c07e00]`}
                              onClick={() => handleOpen(item?.id)}
                           >
                              {item?.title}
                           </AccordionHeader>
                           <AccordionBody
                              className={`${
                                 open === item?.id ? "bg-[#222222]" : ""
                              } text-white px-3`}
                           >
                              {pars(JSON.parse(item?.description))}
                              <br />
                              {item?.video_url && (
                                 <iframe
                                    className="w-[100%]  aspect-video  "
                                    src={item?.video_url}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                 ></iframe>
                              )}
                           </AccordionBody>
                        </Accordion>
                     ))}
                  </div>
                  {faq.map(
                     (item, i) =>
                        item?.faqs?.length > 0 && (
                           <div key={i}>
                              <h2 className="text-[35px] md:text-[40px] text-white mt-10  text-center ">
                                 {item?.name}
                              </h2>
                              <img
                                 className=" w-[200px] mx-auto -mt-2 mb-10"
                                 src={hr}
                                 alt=""
                              />
                              <div className="rounded-md bg-[#222222] ">
                                 {item?.faqs.map((it, i) => (
                                    <Accordion
                                       key={i}
                                       className=""
                                       open={open === it?.id}
                                    >
                                       <AccordionHeader
                                          className={`${
                                             open == it?.id
                                                ? "bg-[#c07e00]"
                                                : ""
                                          } text-white border-b hover:bg-[#c07e00] px-3 text-[14px]  border-[#c07e00]`}
                                          onClick={() => handleOpen(it?.id)}
                                       >
                                          {it?.title}
                                       </AccordionHeader>
                                       <AccordionBody
                                          className={`${
                                             open == it?.id
                                                ? "bg-[#222222]"
                                                : ""
                                          } text-white  px-3`}
                                       >
                                          {pars(JSON.parse(it?.description))}
                                       </AccordionBody>
                                    </Accordion>
                                 ))}
                              </div>
                           </div>
                        )
                  )}
               </div>
            </div>
         </div>
      </>
   );
};

export default Faq;
