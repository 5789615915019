import { useNavigate } from 'react-router-dom';
import logo from "../assets/logo.png"
const parse = require('html-react-parser')

const Invoice = () => {
  const history = useNavigate();
  const handleRoute = () => {
    history("/payment")
  }
  const order = JSON.parse(localStorage.getItem("order"))
let html="";

  for (const [key, value] of Object.entries(order)) {
    let obj = key;
    if (key === "category_id"  ) {
      obj= "Category Id"
    }
   else if (key === "categoryName"  ) {
      obj= "Game:"
    }
   else if (key === "name"  ) {
      obj= "Package:"
    }
   else if (key === "product_id"  ) {
      obj= "Product Id:"
    }
   else if (key === "amount"  ) {
      obj= "Amount:"
    }
    // key.replace(" ","_");
    let newStr = obj.replace("_ID_"," ID ");

    if (obj !=="Category Id" ) {
        if (obj !=="Product Id:") {
          html += `<div className='grid mx-4 grid-cols-2 px-8 border-b border-r border-t border-l border-[#fd648b]  text-xl  text-white gap-8'>
          <h4 className="col-span-1 border-r  py-3 border-[#fd648b]">${newStr}</h4> <h4 className="col-span-1 py-3 text-right  ">${value}</h4>
        </div> `
    }
    }
}

  return (
    <div className='absolute  left-0 top-0 w-[100vw] pb-10 h-[100vh] z-[1000000] bg-[#111111] '>
      <div className='container  flex justify-center  items-center md:py-10 h-full  '>
        <div className='md:w-4/6 lg:w-3/6 w-full bg-[#222222] overflow-my  py-6 mx-auto '>
          <img className='h-20 text-center mx-auto mb-6 ' src={logo} alt="" />
          <span className='h-[6px] w-full block bg-[#fd648b]'></span>
          <div className='flex justify-center px-8 py-3 text-white text-2xl gap-8'>
            <h3 className='text-center text-3xl py-3 '>© FNZ GAMING SHOP</h3>
          </div>
          { parse(html)}
          <button onClick={handleRoute} className="relative inline-flex mt-8 items-center px-12 py-3 ml-8 overflow-hidden md:text-lg text-base font-medium text-white border-2 border-white hover:border-[#DD2476] rounded-full hover:text-white group hover:bg-gray-50">
            <span className="absolute left-0 block w-full h-0 transition-all bg-gradient-to-r from-[#FA5252] to-[#DD2476] opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
            <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
            </span>
            <span className="relative">Continue buying</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Invoice;