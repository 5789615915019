import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";

import { Link, useLocation } from "react-router-dom";
import hr from "../assets/hr1.png";
import PageTitle from "./PageTitle";
const pars = require("html-react-parser");

const Blog = () => {
  const [blog, setBlog] = useState([]);
  const path = useLocation().pathname;
  useEffect(() => {
    axios.get("/api/blog").then((dt) => {
      setBlog(dt?.data?.data);
    });
  }, []);

  return (
    <>
      {path === "/blog" && (
        <PageTitle
          title="Blog - FNZ Gaming Shop"
          description="Blog Description"
        ></PageTitle>
      )}

      <div className="container py-10">
        <h2 className="text-[25px] md:text-[35px] text-white">BLOG</h2>
        <img className="w-[200px] -mt-2 mb-6 " src={hr} alt="" />
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
          {path === "/blog" ? (
            <>
              {blog?.map((item) => (
                <div key={item?.id} className="col-span-1">
                  <div className="bg-[#222222] h-full p-4 rounded-lg">
                    <div className="overflow-hidden rounded-lg">
                      <img
                        className="rounded-lg h-[200px] w-full time transform hover:scale-110 "
                        src={item?.photo}
                        alt=""
                      />
                    </div>
                    <span className="bg-[#c07e00] text-white mt-3 inline-block text-sm rounded-sm px-3 py-1 ">
                      {item?.name}
                    </span>
                    <h2 className="md:text-[30px] text-[22px] text-white time hover:text-[#c07e00] cursor-pointer">
                      <Link to={`/blog/${item?.slug}`}> {item?.title}</Link>{" "}
                    </h2>
                    <p className="text-gray-200">
                      {item?.blog_body?.slice(0, 150)} ...{" "}
                    </p>
                    <div className="flex items-center justify-between">
                      <div className="flex mt-2 gap-4 items-center">
                        <span>
                          <img
                            className="w-10 h-10 rounded-full"
                            src="https://www.androidp1.com/uploads/posts/2022-11/garena-free-fire.webp"
                            alt=""
                          />
                        </span>
                        <div>
                          <h6 className="text-white text-xl">{item?.name}</h6>
                          <span className="text-gray-100">
                            {moment(item?.updated_at).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </span>
                        </div>
                      </div>
                      <Link
                        to={`/blog/${item?.slug}`}
                        className=" rounded px-5 pb-2.5 pt-4 overflow-hidden group bg-white text-black relative hover:bg-gradient-to-r from-[#FA5252] to-[#DD2476]  hover:ring-2 hover:ring-offset-2 hover:ring-[#DD2476] transition-all ease-out duration-300 hover:text-white"
                      >
                        <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-[#111111] opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                        <span className="relative">Read More</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {blog?.slice(0, 3).map((item) => (
                <div key={item?.id} className="col-span-1">
                  <div className="bg-[#222222] h-full p-4 rounded-lg">
                    <div className="overflow-hidden rounded-lg">
                      <img
                        className="rounded-lg h-[200px] w-full time transform hover:scale-110 "
                        src={item?.photo}
                        alt=""
                      />
                    </div>
                    <span className="bg-[#c07e00] text-white mt-3 inline-block text-sm rounded-sm px-3 py-1 ">
                      {item?.name}
                    </span>
                    <h2 className="md:text-[30px] text-[22px] text-white time hover:text-[#c07e00] cursor-pointer">
                      <Link to={`/blog/${item?.slug}`}> {item?.title}</Link>{" "}
                    </h2>
                    <p className="text-gray-200">
                      {item?.blog_body?.slice(0, 150)} ...{" "}
                    </p>
                    <div className="flex items-center justify-between">
                      <div className="flex mt-2 gap-4 items-center">
                        <span>
                          <img
                            className="w-10 h-10 rounded-full"
                            src="https://www.androidp1.com/uploads/posts/2022-11/garena-free-fire.webp"
                            alt=""
                          />
                        </span>
                        <div>
                          <h6 className="text-white text-xl">{item?.name}</h6>
                          <span className="text-gray-100">
                            {moment(item?.updated_at).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </span>
                        </div>
                      </div>
                      <Link
                        to={`/blog/${item?.slug}`}
                        className=" rounded px-5 pb-2.5 pt-4 overflow-hidden group bg-white text-black relative hover:bg-gradient-to-r from-[#FA5252] to-[#DD2476]  hover:ring-2 hover:ring-offset-2 hover:ring-[#DD2476] transition-all ease-out duration-300 hover:text-white"
                      >
                        <span className="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-[#111111] opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                        <span className="relative">Read More</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        {path !== "/blog" && (
          <div className="flex justify-end mt-5">
            <Link
              to={"/blog"}
              className="relative inline-flex items-center justify-center px-12 py-3 overflow-hidden md:text-lg text-base font-medium text-white border-2 border-white hover:border-[#DD2476] rounded-full hover:text-white group hover:bg-gray-50"
            >
              <span className="absolute left-0 block w-full h-0 transition-all bg-gradient-to-r from-[#FA5252] to-[#DD2476] opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
              <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  ></path>
                </svg>
              </span>
              <span className="relative">See all</span>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default Blog;
