import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";
import axios from "axios";

export default function Hero() {
   const [banner, setBanner] = useState([]);

   useEffect(() => {
      axios.get("/api/banners").then((dt) => setBanner(dt.data.data));
   }, []);

   return (
      <>
         <div className="-mt-0.5">
            <Swiper
               pagination={Pagination}
               navigation={true}
               modules={[Navigation, Autoplay]}
               autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
               }}
               className="mySwiper"
            >
               {banner.map((items, i) => (
                  <SwiperSlide key={i}>
                     <img
                        className="w-full lg:h-[450px] md:h-[300px] min-h-[200px]"
                        src={`https://backend.fnzgamingshop.com${items?.photo}`}
                        alt=""
                     />
                  </SwiperSlide>
               ))}
            </Swiper>
         </div>
         {/* <div className="container md:hidden block">
            <Swiper
               pagination={Pagination}
               navigation={true}
               modules={[Navigation, Autoplay]}
               autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
               }}
               className="mySwiper"
            >
               <SwiperSlide>
                  <figure>
                     <img
                        className=" w-[100%] min-h-[130px]"
                        src={img1}
                        alt=""
                     />
                  </figure>
               </SwiperSlide>
               <SwiperSlide>
                  <figure>
                     <img
                        className=" w-[100%] min-h-[130px] "
                        src={img2}
                        alt=""
                     />
                  </figure>
               </SwiperSlide>
            </Swiper>
         </div> */}
      </>
   );
}
