
import React from 'react';
import hr from "../assets/hr1.png"

const PrivacyPolicy = () => {
    return (
    <div className="container ">
          <h2 className='text-[30px] md:text-[50px] text-white pt-10  '>PRIVACY POLICY</h2>
            <img className=' w-[200px] -mt-2 mb-2' src={hr} alt="" />
            <h3 className='md:text-[20px] text-[18px] font-medium pb-4 text-white '>HOME / PRIVACY POLICY</h3>
            <div className=" bg-[#222222] p-10 rounded-lg ">
        <div className="row text-white ">
           <div className="col-md-12">
              <p className='text-[45px] text-center font-sans pb-3' ><span ><strong><span>Privacy Policy</span></strong></span></p>
              <p className='text-[25px] md:text-[35px] text-center font-sans ' ><span ><strong><span >Introduction</span></strong></span></p>
              <p className='font-sans text-[17px] pb-[27px] ' ><span >Welcome to FNZGAMINGSHOP!</span></p>
              <p className='font-sans text-[17px] pb-[27px] '><span >These Terms of Service govern your
                 use of our website at
                 fnzgamingshop.com operated by
                 FNZGAMINGSHOP.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >Our Privacy Policy also regulates
                 your use of our Services and
                 explains how we collect, protect and
                 disclose information as a result of
                 your use of our web pages.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >Your agreement with us includes
                 these terms and our privacy policy
                 You acknowledge that you have read
                 and understood the agreements and
                 agree to abide by them</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >If you do not agree with the
                 Agreement, you may not use the
                 Service, but please email us at
                 Support@fnzgamingshop.com so that we
                 can try to find a solution. These
                 Terms apply to all visitors, users,
                 and others who wish to access or use
                 the Services.</span>
              </p>
              <p  className='text-[25px] md:text-[35px] text-center font-sans'><span ><strong><span >Communications</span></strong></span></p>
              <p className='font-sans text-[17px] pb-[27px] '><span >By using our website, you agree to
                 subscribe to newsletters, marketing
                 or promotional materials, and other
                 data we may send. However, you can
                 opt out of receiving all these
                 contacts from us by following the
                 unsubscribe link or by emailing
                 Support@fnzgamingshop.com.</span>
              </p>
              <p  className='text-[25px] md:text-[35px] text-center font-sans'><span ><strong><span >Purchases</span></strong></span></p>
              <p className='font-sans text-[17px] pb-[27px] '><span >However, you may be asked to give
                 some data applicable to your
                 purchase, including your credit or
                 debit card number, If you wish to
                 buy any product or service available
                 through the Service.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >You represent and warrant
                 that</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >(i) you have the legal right to use
                 any card (s) or other forms of
                 payment for any purchase; And
                 that</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >(ii) the data you give to us is
                 correct, accurate, and
                 complete.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >We may use third-party services to
                 grease payment and completion of
                 purchases. By submitting your data,
                 you grant this third party the right
                 to expose data subject to our
                 privacy policy. We reserve the right
                 to reject or cancel your order at
                 any time for reasons not limited to
                 the availability of the product or
                 service, the description or price of
                 the product or service, the error in
                 your order, or other reasons.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >We have the right to decline or
                 cancel your order if you suspect
                 fraud or unauthorized or illegal
                 deals.</span>
              </p>
              <p  className='text-[25px] md:text-[35px] text-center font-sans'><span ><strong><span >Contests, Sweepstakes, and
                 Promotions</span></strong></span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >Any contests, sweepstakes, or other
                 promotions provided by the Services
                 may be governed by separate terms
                 from these Terms of Service.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >If you participate in any
                 campaigns, please review our Privacy
                 Policy as well as the applicable
                 rules. If the Terms of Service
                 conflict with these Terms of
                 Service, the Terms of Promotion will
                 apply.</span>
              </p>
              <p  className='text-[25px] md:text-[35px] text-center font-sans'><span ><strong><span >Subscriptions</span></strong></span></p>
              <p className='font-sans text-[17px] pb-[27px] '><span >Some parts of the favor are billed
                 on a subscription basis
                 (“Subscription (s)”).
                 You’ll be billed in progress
                 on a recreating and periodic basis
                 (“Billing Cycle”).
                 Billing cycles will be set depending
                 on the type of subscription plan you
                 elect when copping a
                 Subscription.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >At the end of each Billing circle,
                 your Subscription will automatically
                 renew under the accurate same
                 conditions unless you cancel it or
                 FNZGAMINGSHOP cancels it. You may
                 cancel your Subscription renewal
                 either through your online account
                 operation runner or by reaching
                 Support@fnzgamingshop.com client
                 support platoon.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >A valid payment system is needed to
                 reuse the payment for your
                 subscription. You shall give
                 FNZGAMINGSHOP with accurate and
                 complete billing information that
                 may include but isn’t limited
                 to full name, address, state, postal
                 or zip law, telephone number, and
                 valid payment system information. By
                 submitting similar payment data, you
                 automatically authorize
                 FNZGAMINGSHOP to charge all
                 Subscription charges incurred
                 through your account to any similar
                 payment devices.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >Should automatic billing fail to be
                 for any reason, FNZGAMINGSHOP
                 reserves the right to terminate your
                 access to the Service with immediate
                 effect.</span>
              </p>
              <p  className='text-[25px] md:text-[35px] text-center font-sans'><span ><strong><span >Free Trial</span></strong></span></p>
              <p className='font-sans text-[17px] pb-[27px] '><span >FNZGAMINGSHOP may, at its sole
                 discretion, offer a Subscription
                 with a free trial for a limited
                 period of time (“Free
                 Trial”).</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >You may be required to fill up your
                 correct billing data in order to
                 sign up for a Free Trial.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >If you do enter your billing
                 information when signing up for Free
                 Trial, you will not be charged by
                 FNZGAMINGSHOP until Free Trial has
                 expired.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >On the finished day of the Free
                 Trial period, unless you canceled
                 your Subscription, you will be
                 automatically charged the applicable
                 Subscription fees for the type of
                 Subscription you have selected we
                 provide a free trial for real buyers
                 only.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >At any time and without notice,
                 FNZGAMINGSHOP reserves the right to
                 (i) modify Terms of Service of Free
                 Trial offer, or (ii) cancel such
                 Free Trial offer.</span>
              </p>
              <p  className='text-[25px] md:text-[35px] text-center font-sans'><span ><strong><span >Fee Changes</span></strong></span></p>
              <p className='font-sans text-[17px] pb-[27px] '><span >Note any Subscription charges
                 change will be effective at the end
                 of the also-current Billing
                 Cycle.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >FNZGAMINGSHOP will give you
                 reasonable previous notice of any
                 change in Subscription freights to
                 give you an occasion to terminate
                 your Subscription before a similar
                 change becomes effective.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >Your continued use of favor after
                 the Subscription charge change comes
                 into effect constitutes your
                 agreement to pay the modified
                 Subscription charge quantity.</span>
              </p>
              <p  className='text-[25px] md:text-[35px] text-center font-sans'><span ><strong><span >Refunds</span></strong></span></p>
              <p className='font-sans text-[17px] pb-[27px] '><span ><span >We issue refunds for Contracts
                 within </span><strong><span >30 days</span></strong><span >
                 of the original purchase of the </span><a href="https://uniqbd.com/" ><span >Contract</span></a><span >.</span></span>
              </p>
              <p  className='text-[25px] md:text-[35px] text-center font-sans'><span ><strong><span >Content</span></strong></span></p>
              <p className='font-sans text-[17px] pb-[27px] '><span >The content plant on or through
                 this Service is the property of
                 FNZGAMINGSHOP or used with
                 authorization. You may not
                 distribute, modify, transmit,
                 exercise, download, re post, copy,
                 or use said Content, whether in
                 whole or in part, for marketing
                 purposes or for particular gain,
                 without express advance written
                 authorization from us.</span>
              </p>
              <p className='text-[25px] md:text-[35px] text-center font-sans'><span ><strong><span >Analytics</span></strong></span></p>
              <p className='font-sans text-[17px] pb-[27px] '><span >We may use third-party Service
                 Providers to monitor and analyze the
                 use of our Service.</span>
              </p>
              <p  className='text-[25px] md:text-[35px] text-center font-sans'><span ><strong><span >No Use By Minors</span></strong></span></p>
              <p className='font-sans text-[17px] pb-[27px] '><span >The Service is intended for access
                 and uses only by persons progressed
                 at least eighteen (18) times. By
                 penetrating or using the Services,
                 you warrant and represent that
                 you’re at least eighteen (18)
                 times of age and with full
                 authority, rights and powers to
                 enter into this Agreement and to
                 misbehave with all terms and
                 conditions. Unless you’re at
                 least 18 times old, you’re
                 banned from penetrating and using
                 the Services.</span>
              </p>
              <p  className='text-[25px] md:text-[35px] text-center font-sans'><span ><strong><span >Accounts</span></strong></span></p>
              <p className='font-sans text-[17px] pb-[27px] '><span >When you create an account with us,
                 you guarantee that you are over 18
                 years of age and that the
                 information you provide us is always
                 accurate, complete and current.
                 Incorrect, deficient, or obsolete
                 information may affect in immediate
                 termination of your account with the
                 Service.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >You are responsible for maintaining
                 the confidentiality of your account
                 and password, including but not
                 limited to access to your computer
                 and/or account. You agree to accept
                 responsibility for any and all
                 actions or actions that take place
                 under your account and/or password,
                 whether your password is with our
                 Services or third-party services.
                 You must notify us as soon as you
                 become aware of security breaches or
                 unauthorized use of your
                 account.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >You may not use the name of another
                 person or entity as a username or
                 which is not legally available for
                 use, a name or trademark which is
                 subject to the rights of any person
                 or reality other than you, without
                 proper authorization. You may not
                 use an offensive, obscene, or
                 obscene name as a username.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >We reserve the right to refuse
                 services, close accounts, remove or
                 edit content or cancel orders at our
                 own discretion.</span>
              </p>
              <p  className='text-[25px] md:text-[35px] text-center font-sans'><span ><strong><span >Error Reporting and
                 Feedback</span></strong></span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >You can provide us with information
                 and feedback
                 (“Feedback”) directly on
                 Support@fnzgamingshop.com or through
                 third-party sites and tools, with
                 suggestions for improvements, ideas,
                 problems, complaints and other
                 issues related to our Services. You
                 acknowledge and agree that: (i) you
                 will not retain, acquire or claim
                 any intellectual property rights or
                 other rights, title or interest or
                 response or support; (ii) the
                 company may create similar concepts
                 of feedback;</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >(iii) the response does not contain
                 confidential information or
                 proprietary information from you or
                 any third party and (iv) the company
                 is not subject to any
                 confidentiality obligation in the
                 response. If you are unable to
                 transfer ownership of Feedback due
                 to applicable binding law, you grant
                 the Company and its affiliates an
                 exclusive, transferable,
                 unchangeable, free, sub-licensed,
                 unlimited and permanent use right.
                 Feedback in any way and for any
                 purpose (including copying,
                 modifying, creating, publishing,
                 distributing and commercializing
                 derivative works).</span>
              </p>
              <p  className='text-[25px] md:text-[35px] text-center font-sans'><span ><strong><span >Disclaimer Of
                 Warranty</span></strong></span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >These services are provided by the
                 company on an “as is”
                 and “as available”
                 basis, The Company does not provide
                 any representations or warranties of
                 any kind, disclosure, or implied for
                 the conduct of their services, or
                 the information, content, or
                 materials contained therein. You
                 expressly agree that your use of
                 these Services, their Content and
                 any Services or Particulars attained
                 from us is at your sole
                 threat.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >The Company or any person
                 affiliated with the Company makes no
                 warranties or representations
                 regarding integrity, security,
                 reliability, quality, accuracy, or
                 availability. Without limiting the
                 aforesaid matters, the Company or
                 any person affiliated with the
                 Company does not represent or
                 warrant the Services, their content,
                 or any Services or Items received,
                 refrained from receiving,
                 received</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >Errors will be corrected, the
                 services or servers that make it
                 available will be free from viruses
                 or other malicious elements or
                 services or other service management
                 services.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >The Company hereby disclaims all
                 guarantees of any kind, whether
                 express or inferred, statutory or
                 else, including but not limited to
                 any guarantees of
                 merchantability.</span>
              </p>
              <p className='font-sans text-[17px] pb-[27px] '><span >The aforesaid does not affect any
                 warranty which may not be waived or
                 limited under applicable law.</span>
              </p>
              <p className='text-[25px] md:text-[35px] text-center font-sans'><span ><strong><span >Contact Us</span></strong></span></p>
              <p className='font-sans text-[17px] pb-[27px] '><span >Please send your feedback,
                 comments, requests for technical
                 support by email:
                 Support@fnzgamingshop.com.</span>
              </p>
              <p>&nbsp;</p>
           </div>
        </div>
     </div>
    </div>
     
    );
};

export default PrivacyPolicy;