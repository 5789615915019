import React from 'react';
import UseData from './Hooks/UseData';
import hr from "../assets/hr1.png"
import GiftDetails from './GiftDetails';


const Gift = ({name}) => {
    const { products } = UseData()
    return (
        <>
        <div className='container pb-10 pt-4'>
            <h2 className="text-[25px] md:text-[35px] text-white uppercase" >{name}</h2>
            <img className='w-[200px] -mt-2 mb-6 ' src={hr} alt="" />
            <div className="grid grid-cols-12 gap-4 md:gap-6 mt-6 ">
                {
                    products?.gifts?.map((product) => <GiftDetails product={product} key={product.id} />)
                }
            </div>
        </div>
    </>
    );
};

export default Gift;