import axios from 'axios';
import  { useEffect, useState } from 'react';
import { useGoogleOneTapLogin } from "react-google-one-tap-login";
import { toast } from "react-toastify";

const AllData = () => {
    const [token, setToken] = useState(JSON.parse(localStorage.getItem("userToken")))
    const [profile, setProfile]= useState({})
    const [name, setName] = useState()
    const [wallet, setWallet] = useState([])
    const [favorite, setFavorite] = useState()
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [message, setMassage] = useState("");
    const [errorShow, setErrorShow] = useState(false);



      useGoogleOneTapLogin({
          disabled: localStorage.getItem("userToken") ? true : false,
          onError: (error) => console.log(error),
          onSuccess: (response) => {
              setEmail(response?.email);
              axios
                  .post("/api/user/social-login", {
                      email: response?.email,
                  })
                  .then((dt) => {
                      if (dt.data.status === true) {
                          toast.info("Login Success!", {
                              position: "top-right",
                              autoClose: 10000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "dark",
                          });

                        
                          localStorage.setItem(
                              "userToken",
                              JSON.stringify(dt.data)
                          );
                          localStorage.setItem(
                              "number",
                              JSON.stringify(dt?.data?.data?.mobile)
                          );
                          setProfile(dt?.data);
                          setToken(dt.data);
                      } else if (dt.data.status === false) {
                          setLoading(false);
                          setMassage(dt.data.message);
                          setErrorShow(true);
                      }
                  });
          },
          googleAccountConfigs: {
              client_id:
                  "438436582480-4kg9c5rrk2k31pf1oh1rkm0ab75socn2.apps.googleusercontent.com",
          },
      });

    useEffect(()=>{
        setToken(JSON.parse(localStorage.getItem("userToken")))
    }, [])

    useEffect(()=>{
        axios.post("/api/me").then(dt=>{
            
                setProfile(dt?.data)
                setName(dt?.data)
                setFavorite(dt?.data?.favorite_game)
              }).then(error=>{

                if (error.status === 401) {
                    localStorage.removeItem("userToken")
             
                }
              }).catch((error)=>{
                if(error?.response?.status === 401){
                    localStorage.removeItem("userToken")
                    setToken(null)
                    setProfile({})
                }
              })
    }, [])

    const [products, setProducts] = useState([])
    useEffect(()=> {
        axios.get("/api/category").then((dt)=> setProducts(dt?.data?.data))
    }, [])

    return {
         products, token, setToken, setProfile, profile, name, setName, favorite, setFavorite, wallet, setWallet
    }
};

export default AllData;