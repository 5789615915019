import { useState } from "react";
import axios from "axios";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import app from "../helper";
import { toast } from "react-toastify";
import UseData from "../Hooks/UseData";
import { useNavigate, Link } from "react-router-dom";
const auth = getAuth(app);

const Register = () => {
  const [errors, setErrors] = useState([]);
  const [show, setShow] = useState(false);
  const { setToken, setProfile } = UseData();
  const [mobileExist, setMobileExist] = useState(false);
  const history = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    otp: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const chaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      },
      auth
    );
  };

  const sentOtp = () => {
    if (formData.mobile.length !== 11) {
      setErrors({ mobile: ["Mobile number must be 11 digits"] });
      return;
    }
    setShow(true);
    const phoneNumber = "+88" + formData.mobile;
    axios
      .post("/api/check-exist", { mobile: formData.mobile, type: "mobile" })
      .then((dt) => {
        if (dt.data.status === true) {
          if(dt?.data?.data===false){
            chaptcha();
            const appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
              .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                toast.info("OTP Sent!", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              })
              .catch((error) => {
                setShow(false);
                setErrors({ mobile: ["OTP sent failed"] });
              });
          }else{
            setShow(false);
            setErrors({ mobile: ["Mobile number already exist"] });
          }
         
        }
      })
      .catch((error) => {
        setShow(false);
        setErrors(error.response.data.errors);
      });
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    // const code = formData.otp;
    // window.confirmationResult
    //   .confirm(code)
    //   .then((result) => {
    //     const user=  result.user;
    //     if (user) {
          axios
            .post("/api/register", formData)
            .then((dt) => {
              if (dt.data.status === true) {
                toast.info("User Registration Successfully!", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });

                localStorage.setItem("userToken", JSON.stringify(dt.data));

                setProfile(dt?.data);
                setToken(dt.data);
                history("/");
              } else if (dt.data.status === false) {
                toast.error(dt.data.message, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                });
              }
            })
            .catch((error) => {
              setErrors(error.response.data.errors);
            });
      //  }
      // })
      // .catch((error) => {
      //   setShow(true);
      //   setErrors({ otp: ["Invalid OTP"] });
      // });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    //clear errors when user start typing
    if (name === "mobile" && value.length !== 11) {
      setErrors({ mobile: ["Mobile number must be 11 digits"] });
    } else {
      setErrors({ ...errors, [name]: null });
    }
  };

  //check errors exist or not by field
  const hasError = (field) => (errors?.[field] ? "border-red-500" : "");

  const renderErrors = (field) => {
    if (errors && errors[field] && Array.isArray(errors[field])) {
      return errors[field].map((error, index) => (
        <p
          key={index}
          className="flex items-center text-red-400 gap-2 pt-2 text-sm pl-5"
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm0-8h2v6h-2V7z"></path>
            </g>
          </svg>
          {error}
        </p>
      ));
    } else {
      return null; // Return null if there are no errors or if errors[field] is not an array
    }
  };

  return (
    <div className="container">
      <div className="container mx-auto mt-5">
        <div className="max-w-[650px] mx-auto rounded-[16px] border border-gray-500 bg-[#222222] p-[30px]">
          <h3 className="text-4xl mb-5 text-white">Register</h3>

          <button
            style={{
              display: "inline-flex",
              alignItems: "center",
              padding: "0px",
              fontSize: "14px",
              fontWeight: "500",
            }}
            type="button"
            className="w-full rounded-full flex justify-center text-center shadow-none text-gray-600 border border-gray-400 duration-150 hover:border-gray-200"
          >
            <div
              style={{
                marginRight: "10px",
                padding: "10px",
                borderRadius: "2px",
              }}
            >
              <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                <g fill="#000" fillRule="evenodd">
                  <path
                    d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                    fill="#EA4335"
                  ></path>
                  <path
                    d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                    fill="#4285F4"
                  ></path>
                  <path
                    d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                    fill="#FBBC05"
                  ></path>
                  <path
                    d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                    fill="#34A853"
                  ></path>
                  <path fill="none" d="M0 0h18v18H0z"></path>
                </g>
              </svg>
            </div>
            <span
              className="text-white"
              style={{
                paddingRight: "10px",
                fontWeight: "500",
                paddingLeft: "0",
                paddingTop: "10px",
                paddingBottom: "10px",
              }}
            >
              Signup with Google
            </span>
          </button>
          <hr className="mt-5" />
          <p className="text-center transform -translate-y-3 z-[-1]">
            <span className="inline-block px-3 bg-[#222222] text-white">
              Or
            </span>
          </p>
          <form action="mt-5" onSubmit={(e) => formSubmit(e)}>
            <div>
              <label className="text-white pb-2 block pl-5">
                Full Name <span className="text-red-400">*</span>
              </label>
              <input
                type="text"
                placeholder="Full Name"
                name="name"
                onChange={handleChange}
                className={`${hasError(
                  "name"
                )}  w-full rounded-full bg-transparent py-2 px-4 text-white border border-gray-400 duration-150 focus:border-gray-100 focus:outline-0 focus:ring-0`}
              />
              {renderErrors("name")}
            </div>
            <div className="mt-4">
              <label className="text-white pb-2 block pl-5">
                Mobile <span className="text-red-400">*</span>{" "}
              </label>
              <div className="flex relative">
                <input
                  type="number"
                  name="mobile"
                  onChange={handleChange}
                  placeholder="017xxxxxxxx"
                  className={`${hasError(
                    "mobile"
                  )} w-full rounded-full bg-transparent py-2 px-4 text-white border border-gray-400 duration-150 focus:border-gray-100 focus:outline-0 focus:ring-0`}
                />
                
                {/* {!show ? (
                  <button
                    type="button"
                    onClick={sentOtp}
                    className="absolute -right-2 text-nowrap mx-2 py-2 hover:bg-transparent border border-white hover:text-white text-500 px-4 bg-white rounded-full"
                  >
                    Sent OTP
                  </button>
                ) : (
                  <button
                    type="button"
                    className="absolute -right-2 text-nowrap mx-2 py-2 hover:bg-transparent border border-white hover:text-white text-500 px-4 bg-white rounded-full"
                  >
                    Sent
                  </button>
                )} */}
              </div>
              {renderErrors("mobile")}
            </div>
            {/* {show && (
              <div className="mt-4">
                <label className="text-white pb-2 block pl-5">
                  OTP <span className="text-red-400">*</span>
                </label>
                <input
                  type="number"
                  name="otp"
                  placeholder="123456"
                  onChange={handleChange}
                  className={`${hasError(
                    "otp"
                  )} w-full rounded-full bg-transparent py-2 px-4 text-white border border-gray-400 duration-150 focus:border-gray-100 focus:outline-0 focus:ring-0`}
                />
                {renderErrors("otp")}
              </div>
            )} */}
            <div className="mt-4">
              <label className="text-white pb-2 block pl-5">
                Email <span className="text-red-400">*</span>{" "}
              </label>
              <input
                type="email"
                name="email"
                onChange={handleChange}
                placeholder="Email Address"
                className={`${hasError(
                  "email"
                )} w-full rounded-full bg-transparent py-2 px-4 text-white border border-gray-400 duration-150 focus:border-gray-100 focus:outline-0 focus:ring-0`}
              />
              {renderErrors("email")}
            </div>
            <div className="flex flex-col md:flex-row gap-4">
              <div className="mt-4 w-full">
                <label className="text-white pb-2 block pl-5">
                  Password <span className="text-red-400">*</span>
                </label>
                <input
                  type="password"
                  name="password"
                  onChange={handleChange}
                  className={`${hasError(
                    "password"
                  )} w-full rounded-full bg-transparent py-2 px-4 text-white border border-gray-400 duration-150 focus:border-gray-100 focus:outline-0 focus:ring-0`}
                  placeholder="*******"
                />
                {renderErrors("password")}
              </div>
              <div className="mt-4 w-full">
                <label className="text-white pb-2 block pl-5">
                  Confirm Password <span className="text-red-400">*</span>
                </label>
                <input
                  type="password"
                  placeholder="*********"
                  onChange={handleChange}
                  className={`${hasError(
                    "password_confirmation"
                  )} w-full rounded-full bg-transparent py-2 px-4 text-white border border-gray-400 duration-150 focus:border-gray-100 focus:outline-0 focus:ring-0`}
                  name="password_confirmation"
                />
                {renderErrors("password_confirmation")}
              </div>
            </div>
            <button
              className="w-full rounded-full px-5 pb-2.5 pt-4  mt-[30px]  py-2 overflow-hidden group bg-white text-black relative hover:bg-gradient-to-r from-[#FA5252] to-[#DD2476]  hover:ring-2 hover:ring-offset-2 hover:ring-[#DD2476] transition-all ease-out duration-300 hover:text-white "
              type="submit"
            >
              Create Account
            </button>
            <p className="text-center pt-2 text-white">
              Have an account?{" "}
              <Link to="/login" className="text-[#FA5252]">
                Login Here
              </Link>
            </p>
          </form>
        </div>
      </div>
      <div id="sign-in-button"></div>
    </div>
  );
};

export default Register;
