import React, { useEffect, useState } from "react";
import EndProducts from "./EndProducts";
import Hero from "./Hero";
import ListOrder from "./ListOrder";
import Products from "./Products";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import Gift from "./Gift";
import Subscriptions from "./Subscriptions";
import PageTitle from "./PageTitle";
import Blog from "./Blog";
import Voucher from "./Voucher";
import ReactPixel from "react-facebook-pixel";
import FollowUs from "./FollowUs";

const Home = () => {
  const [spinner, setSpinner] = useState(true);
  const [notices, setNotices] = useState("");
  const [modal, setModal] = useState(true);

  // It will be executed before rendering
  useEffect(() => {
    const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init("1684319201976128", advancedMatching, options);
    ReactPixel.pageView();

    setTimeout(() => setSpinner(false), 500);
  }, []);
  useEffect(() => {
    axios
      .post("/api/notices", {
        type: "Board",
        page: 0,
      })
      .then((dt) => {
        setNotices(dt?.data?.data?.notices);
        // setModal((dt?.data?.data?.modal));
      });
  }, []);

  return (
    <>
      <PageTitle
        title="Home - FNZ Gaming Shop"
        description="Page Description"
      ></PageTitle>
      {spinner ? (
        <div className="w-[100vw] h-[110vh] bg-black flex justify-center items-center z-[10000] fixed -top-10  ">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#4fa94d"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <Hero />
          {notices[0]?.status && (
            <div
              className={`${
                modal ? "block " : "hidden"
              } transform container rounded-md bg-white border border-[#FA5252] time py-4 mt-5 text-black`}
            >
              <div className="flex justify-between ">
                <div>
                  {" "}
                  <h4 className="text-[#FA5252] text-[25px]">
                    <i className="fa-solid fa-triangle-exclamation"></i> Notice
                  </h4>
                  <h3>{notices[0]?.notice}</h3>
                </div>
                <button
                  className=" rounded-md px-6  text-gray-500"
                  onClick={() => setModal(false)}
                >
                  <i className="fa-solid text-xl fa-xmark"></i>
                </button>
              </div>
            </div>
          )}
          <EndProducts name="INGAMES" />
          <Voucher name="VOUCHERS" />
          <Products name="POPULAR GAMES" />
          <Gift name="Gift Cards" />
          <Subscriptions name="Subscriptions" />
          <ListOrder />
          <Blog />
          <FollowUs />
        </>
      )}
    </>
  );
};

export default Home;
