const SelectItems = ({ item, info, setInfo }) => {
  return (
    <>
      {item?.stock !== 0 ? (
        <div
          onClick={() => setInfo(item)}
          className={`${
            info?.id === item?.id
              ? " border-[#e12b70] bg-[#111111] "
              : "hover:border-[#e12b70] border-gray-700"
          } cursor-pointer col-span-1 border  text-center py-2 rounded-md  `}
        >
          <h3 className="text-white md:text-2xl ">{item?.name}</h3>
          <p className="text-[#3effa5] text-lg ">
            <i className="fa-solid fa-check"></i> In Stock {item?.stock}
          </p>
          <h6
            className={`${
              info?.id === item?.id
                ? "border-[#e12b70]"
                : "hover:border-[#e12b70]"
            } text-[#29cef6] md:text-2xl `}
          >
            {item?.sale_price_view}
          </h6>
        </div>
      ) : (
        <div
          className={`border -gray-600  col-span-1  text-center py-2 rounded-md  `}
        >
          <h3 className="text-gray-400 md:text-2xl ">{item?.category?.name}</h3>
          <h6 className="text-[#4d8391] md:text-2xl ">
            {item?.sale_price_view}
          </h6>
          <p className="text-[#44c086] ">
            <i className="fa-sharp fa-solid fa-xmark"></i> Not Available
          </p>
        </div>
      )}
    </>
  );
};

export default SelectItems;
