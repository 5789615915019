import React, { useState } from "react";
import logo from "../assets/logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import UseData from "./Hooks/UseData";
import $ from "jquery";
import SIdeBar from "./SIdeBar";
import userImg from "../assets/user.png";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [hovers, setHovers] = useState(false);
  const { token, setToken, profile, setProfile } = UseData();
  const history = useNavigate();
  const logout = () => {
    axios.post("/api/logout").then((dt) => {
      if (dt.data.status === true) {
        localStorage.removeItem("userToken");
        setToken(null);
        setProfile({});
        history("/");
        toast.info("Logout Successfully Done!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    });
  };

  const backend_url = process.env.REACT_APP_BACKEND_BASE_URL;

  $(".custom-nav .li").on("click", function () {
    setHovers(true);
  });
  const handle = () => {
    setHovers(false);
  };

  return (
    <>
      <header className="bg-[#222222] fixed top-0 left-0 w-full z-50 ">
        <div className="container">
          <div className="flex  items-center text-center justify-between">
            <div className=" flex  relative items-center">
              <Link to="/">
                <img className="h-[58px] md:h-[75px] mr-6 " src={logo} alt="" />
              </Link>
              <ul
                onClick={() => setOpen(false)}
                className={`${
                  open ? "hidden" : " hidden "
                } bg-[#222222] space-y-2 md:space-y-0 md:bg-transparent md:flex md:ml-8   -left-[16px] md:-left-0 py-6 md:py-0  top-[50px] w-[100vw] md:w-full   gap-4`}
              >
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "  bg-gradient-to-r from-[#FA5252] to-[#DD2476] text-white md:px-4  link  pt-2 pb-1 "
                        : " md:px-4 pt-2 pb-1  bg-white  link"
                    }
                    to="/"
                  >
                    HOME
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "  bg-gradient-to-r from-[#FA5252] to-[#DD2476] text-white md:px-4  link  pt-2 pb-1 "
                        : " md:px-4 pt-2 pb-1  bg-white  link"
                    }
                    to="games"
                  >
                    GAMES
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "  bg-gradient-to-r from-[#FA5252] to-[#DD2476] text-white md:px-4  link  pt-2 pb-1 "
                        : " md:px-4 pt-2 pb-1  bg-white  link"
                    }
                    to="/lottery"
                  >
                    LUCKY DRAW
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "  bg-gradient-to-r from-[#FA5252] to-[#DD2476] text-white md:px-4  link  pt-2 pb-1 "
                        : " md:px-4 pt-2 pb-1  bg-white  link"
                    }
                    to="/review"
                  >
                    REVIEW
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "  bg-gradient-to-r from-[#FA5252] to-[#DD2476] text-white md:px-4  link  pt-2 pb-1 "
                        : " md:px-4 pt-2 pb-1  bg-white  link"
                    }
                    to="/blog"
                  >
                    BLOG
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "  bg-gradient-to-r from-[#FA5252] to-[#DD2476] text-white md:px-4  link  pt-2 pb-1 "
                        : " md:px-4 pt-2 pb-1  bg-white  link"
                    }
                    to="/faq"
                  >
                    FAQ
                  </NavLink>
                </li>
                {!token && (
                  <Link className="mt-4" to="/login">
                    <button className="md:hidden block link  ml-[4px]  w-full  rounded-sm  pr-[32px] text-center py-2  bg-white text-black md:hover:text-[#c5284e] ">
                      LOGIN
                    </button>
                  </Link>
                )}
              </ul>
            </div>
            <div className=" flex md:gap-8">
              {!profile?.api_token && (
                <>
                  <Link
                    to="/login"
                    className="hidden  relative md:inline-flex items-center justify-start px-8 py-2 overflow-hidden font-medium transition-all bg-[#ffffff] text-black rounded hover:bg-[#111111] group"
                  >
                    <span className="w-48 h-48 rounded rotate-[-40deg] bg-gradient-to-r from-[#FA5252] to-[#DD2476] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
                    <span className="relative w-full text-left text-black text-lg tracking-wider transition-colors duration-300 ease-in-out group-hover:text-white">
                      LOGIN
                    </span>
                  </Link>

                  <Link
                    to="/register"
                    className="hidden  relative md:inline-flex items-center justify-start px-8 py-2 overflow-hidden font-medium transition-all bg-[#ffffff] text-black rounded hover:bg-[#111111] group"
                  >
                    <span className="w-48 h-48 rounded rotate-[-40deg] bg-gradient-to-r from-[#FA5252] to-[#DD2476] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
                    <span className="relative w-full text-left text-black text-lg tracking-wider transition-colors duration-300 ease-in-out group-hover:text-white">
                      REGISTER
                    </span>
                  </Link>
                </>
              )}
              {profile?.api_token && (
                <div className=" flex items-center relative group md:mr-0 mr-9">
                  <div className="mx-auto  flex  items-center justify-center">
                    <div className=" ">
                      {profile?.data?.photo === "profile.png" ? (
                        <div className="  rounded-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] ">
                          <img
                            onClick={handle}
                            className="  block w-9 h-9 md:w-10 md:h-10 rounded-full "
                            src={userImg}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div className="  rounded-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                          <img
                            onClick={handle}
                            className="  block w-9 h-9 md:w-10 md:h-10 rounded-full "
                            src={backend_url + profile?.data?.photo}
                            alt={profile?.data?.name}
                          />
                        </div>
                      )}
                    </div>
                    <span className="hidden md:block pl-2 md:text-[20px] text-white text-[18px] tracking-[2px] font-medium">
                      {profile?.data?.name}{" "}
                      <i className="fa-solid fa-angle-down"></i>
                    </span>
                  </div>
                  <div
                    className={`${
                      hovers
                        ? "opacity-0 scale-y-0 md:group-hover:opacity-100 md:group-hover:scale-y-100"
                        : "group-hover:opacity-100 group-hover:scale-y-100"
                    }  z-[100] transform scale-y-0 time origin-top -translate-y-6    group-hover:translate-y-0 absolute rounded-lg bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] top-[58px] w-60 right-0 bg-[#111111]`}
                  >
                    <ul className="bg-[#111111] custom-nav rounded-lg py-3  space-y-2 ">
                      <li className="px-4 pb-2 pt-[9px]  text-white li hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                        <Link className="w-full block" to="/profile">
                          <span>
                            <i className="fa-solid mr-1 fa-user-secret"></i>
                          </span>{" "}
                          MY PROFILE
                        </Link>
                      </li>
                      <li className="px-4 pb-2 pt-[9px] text-white li hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                        <Link className="w-full block" to="/orders">
                          <span>
                            <i className="fa-solid  mr-1 fa-cart-plus"></i>
                          </span>{" "}
                          MY ORDERS
                        </Link>
                      </li>
                      <li className="px-4 pb-2 pt-[9px] text-white li hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                        <Link className="w-full block" to="/voucher-orders">
                          <span>
                            <i className="fa-solid  mr-1 fa-gift"></i>
                          </span>{" "}
                          VOUCHER ORDERS
                        </Link>
                      </li>
                      <li className="px-4 pb-2 pt-[9px] text-white li hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                        <Link className="w-full block" to="/wallet">
                          <span>
                            <i className="fa-solid  mr-1 fa-wallet"></i>
                          </span>{" "}
                          MY WALLET
                        </Link>
                      </li>
                      <li className="px-4 pb-2 pt-[9px] text-white li hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500">
                        <Link className="w-full block" to="/history">
                          <span>
                            <i className="fa-solid mr-1  fa-database"></i>
                          </span>{" "}
                          LOTTERY HISTORY
                        </Link>
                      </li>
                      <li
                        onClick={logout}
                        className="px-4 pb-2 pt-[9px] li text-white hover:bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500"
                      >
                        <button className="w-full text-center block">
                          <span>
                            <i className="fa-solid mr-1  fa-arrow-right-from-bracket"></i>
                          </span>{" "}
                          LOG OUT
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      <div className="h-[50px] md:h-[76px]"></div>
      <div className="!w-full z-[11111111111] fixed left-0 bottom-0 flex justify-between pr-[16px] py-3 bg-[#111111] md:hidden">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive
              ? "text-[#bf2047] router-link-active pl-[16px] "
              : "text-[#ffffff] router-link-active pl-[16px]"
          }
        >
          <i className="fa mr-1 fa-home"></i>
          <span>Home</span>
        </NavLink>
        <NavLink
          to="/orders"
          className={({ isActive }) =>
            isActive
              ? "text-[#bf2047] router-link-active pl-[16px] "
              : "text-[#ffffff] router-link-active pl-[16px]"
          }
        >
          <i className="fa mr-1 fa-shopping-cart"></i>
          <span>Orders</span>
        </NavLink>
        <NavLink
          to="/profile"
          className={({ isActive }) =>
            isActive
              ? "text-[#bf2047] router-link-active pl-[16px] "
              : "text-[#ffffff] router-link-active pl-[16px]"
          }
        >
          <i className="fa mr-1 fa-user"></i>
          <span>Profile</span>
        </NavLink>
        {profile?.api_token ? (
          <button
            onClick={logout}
            className="text-[#ffffff] -link-active pl-[16px]"
          >
            <i className="fa mr-1 fa-sign-out inline-block"></i>
            <span>Logout</span>
          </button>
        ) : (
          <NavLink
            to="/login"
            className={({ isActive }) =>
              isActive
                ? "text-[#bf2047] router-link-active pl-[16px] "
                : "text-[#ffffff] router-link-active pl-[16px]"
            }
          >
            <i className="fa mr-1 fa-sign-out"></i>
            <span>LogIn</span>
          </NavLink>
        )}
      </div>
      <SIdeBar
        open={open}
        setOpen={setOpen}
        profile={profile}
        logout={logout}
      />
    </>
  );
};

export default Header;
