import React from "react";
import { Helmet } from "react-helmet-async";

const PageTitle = ({ title, description }) => {
  return (
    // <Helmet>
    //   <title>{title} - Md. Farid Hossain Portfolio</title>
    // </Helmet>

    <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
</Helmet>



  );
};

export default PageTitle;
