import axios from 'axios';
import  { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import hr from "../assets/hr1.png"
import women from "../assets/women.jpg"
import UseData from './Hooks/UseData';
import ProgressBar from "@ramonak/react-progress-bar";
import { toast } from 'react-toastify';
import PageTitle from './PageTitle';
import { useNavigate } from 'react-router-dom';
const pars = require('html-react-parser')

const Lottery = () => {
    const [show, setShow] = useState(false)
    const { token } = UseData()
    const [allData, setAllData] = useState({})
    const history = useNavigate();
    useEffect(() => {
        axios.get("/api/lottery").then((dt) => {
            setAllData(dt.data.data);
        })
    }, [])

    const handleCheck = () => {
        if (token?.api_token) {
            Swal.fire({
                title: 'Purchase ticket charge from your Wallet Balance?',
                showCancelButton: true,
                confirmButtonText: 'Buy Ticket',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                   
                    //   api call
                    axios.post(`/api/lucky-draw/${allData?.id}`).then(dt => {
                        if (dt?.data?.status=== true) {
                            toast.success(dt?.data?.message, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                            });
    
    
                        }else{
                            toast.error(dt?.data?.message, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "dark",
                            });
                        }
    
    
                    })
                } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                }
            })
        }else{
            history("/login")
            toast.error("Please Login First", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        }
    }
    const [currentTime, setCurrentTime] = useState(Date.now());
    const targetTime = new Date(`${allData?.finished_at}`).getTime();
    const timeBetween = targetTime - currentTime;
    const seconds = Math.floor((timeBetween / 1000) % 60);
    const minutes = Math.floor((timeBetween / 1000 / 60) % 60);
    const hours = Math.floor((timeBetween / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeBetween / (1000 * 60 * 60 * 24));

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(Date.now());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
          <PageTitle
            title="Lottery - FNZ Gaming Shop"
            description="Lottery Description"
         ></PageTitle>
        <div className='container'>
            <h2 className='text-[30px] md:text-[50px] text-white pt-10  '>TICKET</h2>
            <img className=' w-[200px] -mt-2 mb-2' src={hr} alt="" />
            <h3 className='md:text-[20px] text-[18px] text-white font-medium pb-4 '>HOME / TICKET</h3>
            <div className='bg-[#222222] p-6 md:p-10 rounded-lg'>
                <div className='text-center flex justify-center gap-4'>
                    <button onClick={() => setShow(true)} className={`${show ? "border-b-[3px] border-yellow-400" : "border-b-[3px] border-white "} md:text-[20px] text-[18px] font-medium  px-2  rounded-b-[5px] text-white`} >Winner History</button>
                    <button onClick={() => setShow(false)} className={`${show ? "border-b-[3px] border-white" : "border-b-[3px] border-yellow-400"} md:text-[20px] text-[18px] font-medium  px-2  rounded-b-[5px] text-white`}>Live</button>
                </div>
                {
                    show ? <>
                        <div className='time'>
                            <h2 className='text-[40px] text-white text-center font-semibold pt-6 '>LUCKY DRAW</h2>
                            <h3 className='text-[30px] text-white text-center font-semibold '>Winner List</h3>
                            <div className='grid grid-cols-12 items-center gap-6 mt-8  '>
                                {
                                    allData?.winners_list?.map((person, i) => <div key={i} className=" col-span-12 lg:col-span-6 flex justify-between rounded-md items-center  py-3 px-4 bg-[#222222] border-[#787878] border-2  ">
                                        <div className='flex  items-center'>
                                            <img className='h-16 w-16 rounded-lg object-cover' src={person?.photo} alt="" />
                                            <div className='pl-5'>
                                                <h5 className='text-2xl text-white '>{person?.name}</h5>
                                                <button className='bg-[#6c757d] pt-1 px-2 tracking-wide rounded-sm text-white  '>FNZ-{person?.user_id}</button>
                                            </div>
                                        </div>
                                        <span className='mr-4  bg-[#e02873] w-8 rounded-sm h-8 font-semibold  flex justify-center items-center text-white '>{i + 1}</span>
                                    </div>)
                                }
                            </div>
                        </div>
                        <div>
                        </div>
                    </> : <>
                        <div className='grid time grid-cols-12 mt-6 md:gap-8'>
                            <div className='col-span-12 md:col-span-5'>
                                <img className=' w-[100%] md:w-full rounded-md' src={women} alt="" />

                            </div>
                            <div className="col-span-12 md:col-span-7 text-white ">
                                <div className='flex justify-between items-center'>
                                    <div>
                                        <h3 className='text-[25px] md:text-[35px] '>{allData?.title}</h3>
                                        <h5 className='md:text-lg text-base  ' >{allData?.subtitle}</h5>
                                        <h6 className='md:text-lg text-base' >Price</h6>
                                    </div>
                                    {
                                        timeBetween > 0 && <div>
                                        <p className='text-2xl text-center'>Lottery Time Remains</p>
                                        <p className="text-3xl flex gap-2">
                                            <div>
                                            <span className='w-10 h-10  bg-red-500 flex justify-center items-center'>{days} </span>
                                            <p className='text-lg text-center'>Days</p>
                                                </div>
                                                <div>  <span className='w-10 h-10  bg-red-500 flex justify-center items-center'>{hours} </span><p className='text-lg text-center'>Hour</p></div>
                                                <div><span className='w-10 h-10  bg-red-500 flex justify-center items-center'>{minutes} </span><p className='text-lg text-center'>Min</p></div>
                                                <div>   <span className='w-10 h-10  bg-red-500 flex justify-center items-center'>{seconds}</span><p className='text-lg text-center'>Sec</p></div>
                                        </p>
                                    </div>
                                    }
                                </div>
                                <p className='text-[25px] md:text-[35px]'>BDT. {allData?.amount}</p>
                                <div className='grid grid-cols-12'>
                                    <div className='col-span-4'>
                                        <h2 className='md:text-lg text-base'>Prize Quantity</h2>
                                        <span className='text-[25px] md:text-[35px]'>{allData?.winners}</span>
                                    </div>
                                    <div className='col-span-8'>
                                        <h2 className='md:text-lg text-base'>Winner Prize</h2>
                                        <span className='text-[25px] md:text-[35px]' >{allData?.prize}</span>
                                    </div>
                                </div>
                                <h2 className='md:text-lg text-base'>Current Participants ( {allData?.participants_count} )</h2>
                                <div title={allData?.participants_count}>
                                <ProgressBar  baseBgColor="#111111" bgColor="#facc15" completed={allData?.participants_count} maxCompleted={500}  customLabel=" " />
                                </div>
                             
                                {/* <span className='w-[100%] md:full bg-yellow-400 h-2 block rounded-full mt-2'></span> */}
                             {
                                timeBetween > 0  &&  <button onClick={handleCheck} className="relative inline-flex mt-8 items-center px-12 py-3 overflow-hidden md:text-lg text-base font-medium text-white border-2 border-white hover:border-[#DD2476] rounded-full hover:text-white group hover:bg-gray-50">
                                <span className="absolute left-0 block w-full h-0 transition-all bg-gradient-to-r from-[#FA5252] to-[#DD2476] opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                                <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                </span>
                                <span className="relative">Click To Buy</span>
                            </button>
                             }
                            </div>
                        </div>
                    </>
                }
            </div>

            <div className='bg-[#222222] px-6 text-white  md:px-10 py-4 md:py-6 rounded-lg mt-8'>
                <h2 className='text-[25px] md:text-[35px]' >How to participate?</h2>
                {allData?.description}
            </div>
            <div className='bg-[#222222] px-6 text-white  md:px-10 py-4 md:py-6 rounded-lg mt-8'>
                <h2 className='text-[25px] md:text-[35px]' >How to Order?</h2>
                <iframe className='w-[100%]  aspect-video  ' src={`${allData?.video_url ? allData?.video_url : "https://www.youtube.com/embed/Vj0Ibc-yzjQ"}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
        </div>
        </>
    );
};

export default Lottery;