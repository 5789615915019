import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UseData from "./Hooks/UseData";
import { FaFacebook } from "react-icons/fa";
import { BsWhatsapp, BsTelegram } from "react-icons/bs";
import { AiOutlineRight } from "react-icons/ai";
import logo from "../assets/logo.png";
import bkash from "../assets/payment/bkass.png";
import rocket from "../assets/payment/roket1-removebg-preview.png";
import nagad from "../assets/payment/nagad1.png";

const Footer = () => {
   const [show, setShow] = useState(false);
   const [service, setService] = useState([]);
   const { profile } = UseData();

   useEffect(() => {
      axios.get("/api/supports").then((dt) => setService(dt?.data?.data));
   }, []);

   return (
      <>
         <footer className="m-auto w-full text-gray-50 bg-[#222222] mt-12 pt-8">
            <div className="container">
               <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 px-1.5">
                  <div className="md:-mt-4 md:ml-0 -ml-3 ">
                     <img src={logo} alt="" className="lg:h-[68px] h-14 " />
                  </div>

                  <div>
                     <h3 className="text-xl mb-1 border-b inline-block">
                        Usefull Link
                     </h3>
                     <Link to={"/faq"} className="flex items-center">
                        <AiOutlineRight size={16} />
                        <span className="hover:text-[#e40a5d] duration-500 ml-1 mt-0.5 hover:ml-2 ">
                           FAQ
                        </span>
                     </Link>
                     <Link to={"/games"} className="flex items-center">
                        <AiOutlineRight size={16} />
                        <span className="hover:text-[#e40a5d] duration-500 ml-1 mt-0.5 hover:ml-2 ">
                           Games
                        </span>
                     </Link>
                     <Link to={"/review"} className="flex items-center">
                        <AiOutlineRight size={16} />
                        <span className="hover:text-[#e40a5d] duration-500 ml-1 mt-0.5 hover:ml-2 ">
                           Reviews
                        </span>
                     </Link>
                     <Link to={"/lottery"} className="flex items-center">
                        <AiOutlineRight size={16} />
                        <span className="hover:text-[#e40a5d] duration-500 ml-1 mt-0.5 hover:ml-2 ">
                           Lucky Draw
                        </span>
                     </Link>
                     <Link to={"/privacy-policy"} className="flex items-center">
                        <AiOutlineRight size={16} />
                        <span className="hover:text-[#e40a5d] duration-500 ml-1 mt-0.5 hover:ml-2 ">
                           Privacy
                        </span>
                     </Link>
                     <Link
                        to={"/terms-and-conditions"}
                        className="flex items-center"
                     >
                        <AiOutlineRight size={16} />
                        <span className="hover:text-[#e40a5d] duration-500 ml-1 mt-0.5 hover:ml-2 ">
                           Terms & Condition
                        </span>
                     </Link>
                  </div>
                  <div>
                     <div>
                        <h3 className="text-xl mb-2 border-b inline-block">
                           Payment
                        </h3>

                        <img className=" h-10 mb-2 -ml-3" src={rocket} alt="" />
                        <img className=" h-9 -ml-1" src={nagad} alt="" />
                        <div className="flex items-center">
                           <p className="text-3xl italic font-serif">
                              <span className="text-[#f92a79]">b</span>kash{" "}
                           </p>
                           <img className=" h-8 mt-2" src={bkash} alt="" />
                        </div>
                     </div>
                  </div>
                  <div>
                     <h3 className="text-xl mb-2 border-b inline-block">
                        Follow me
                     </h3>
                     <div>
                        <a
                           href="https://www.facebook.com/fnzgamingofficial"
                           target="_blank"
                        >
                           <FaFacebook
                              size={24}
                              className="mr-3 inline-block hover:text-[#e40a5d] duration-500"
                           />
                        </a>
                        <a
                           href="https://api.whatsapp.com/message/EP72V4FUFQDAN1?autoload=1&app_absent=0"
                           target="_blank"
                        >
                           <BsWhatsapp
                              size={24}
                              className="mr-3 inline-block hover:text-[#e40a5d] duration-500"
                           />
                        </a>
                        <a
                           href="https://t.me/offerfnz?fbclid=IwAR2MvpdGPdRNxFX1qQat2erQ4R1HUbpoROleoxO6mB-KwYDF6XJ6LcaMv-U"
                           target="_blank"
                        >
                           <BsTelegram
                              size={24}
                              className="mr-3 inline-block hover:text-[#e40a5d] duration-500"
                           />
                        </a>
                     </div>
                  </div>
               </div>
               <div className="text-center mt-6">
                  <p className="font-medium text-white border-t pt-4">
                     © 2023 . Fnz Gaming Shop All Rights Reserved
                  </p>
               </div>
            </div>
         </footer>
         <ul className="fixed right-6 md:bottom-4 bottom-14">
            <li className="bg-[#e40a5d] hover:bg-[#dcb725] time md:w-14 md:h-14 w-10 h-10 rounded-full flex justify-center items-center relative ">
               <span
                  className={`${
                     show ? "scale-0" : "scale-100"
                  } w-[70px] bg-[#e40a5d] transform  text-white top-2 md:top-4 text-center absolute -left-[190%] md:-left-[150%]`}
               >
                  Support
               </span>
               <button className="">
                  <i
                     onClick={() => setShow(true)}
                     className={`${
                        show ? "hidden" : "block"
                     } text-white md:text-2xl fa fa-headphones md:w-14 md:h-14 w-10 h-10 flex justify-center items-center`}
                  ></i>
                  <i
                     onClick={() => setShow(false)}
                     className={`${
                        show ? "block" : "hidden"
                     } text-white md:text-2xl  fa fa-times md:w-14 md:h-14 w-10 h-10 flex justify-center items-center`}
                  ></i>
               </button>
               <ul
                  className={`${
                     show
                        ? "opacity-100 visible scale-100"
                        : "opacity-0 invisible scale-0"
                  } absolute bottom-[40px] md:bottom-[55px] time origin-center`}
               >
                  {service?.map((item, i) => (
                     <div key={i}>
                        <li
                           key={i}
                           className={` ${
                              item?.color === "#279fda"
                                 ? "bg-[#279fda]"
                                 : "bg-[#1cbe43]"
                           } hover:bg-[#dcb725] relative  time md:w-14 md:h-14 w-10 h-10 rounded-full flex justify-center items-center`}
                        >
                           <span className="w-[70px] bg-[#e40a5d] text-white top-2 md:top-4 text-center absolute -left-[190%] md:-left-[150%]">
                              {item?.name}
                           </span>
                           <a
                              rel="noreferrer"
                              target="_blank"
                              href={item?.link}
                              className=""
                           >
                              <i
                                 className={` ${item?.icon} md:text-2xl text-white fa fa-times md:w-14 md:h-14 w-10 h-10 flex justify-center items-center`}
                              ></i>
                           </a>
                        </li>
                        <br />
                     </div>
                  ))}
               </ul>
            </li>
         </ul>
      </>
   );
};

export default Footer;
