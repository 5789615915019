import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import hr from "../assets/hr1.png";
import UseData from "./Hooks/UseData";
import SelectItems from "./SelectVoucherItems";
import SelectPayment from "./SelectPayment";
import $ from "jquery";
import PageTitle from "./PageTitle";
import { toast } from "react-toastify";
import image from "../assets/free-fire.jpg";
import "./product.css";
import { ThreeDots } from "react-loader-spinner";
const parse = require("html-react-parser");

const SingleVoucher = () => {
  const { productName } = useParams();
  const [allData, setAllData] = useState({});
  const [info, setInfo] = useState({});
  const [payment, setPayment] = useState({});
  const [details, setDetails] = useState("");
  const [htmltext, sethtmltext] = useState("");
  const [payments, setPayments] = useState("");
  const { token } = UseData();
  const history = useNavigate();

  useEffect(() => {
    axios.get(`/api/voucher-products/${productName}`).then((dt) => {
      sethtmltext(JSON.parse(dt?.data.data.html));
      setDetails(JSON.parse(dt?.data?.data?.category?.details));
      setAllData(dt.data.data);
    });

    axios.get(`/api/payment-methods`).then((dt) => {
      setPayments(dt.data.data);
    });
  }, [productName]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (token?.api_token) {
      let formdata = new FormData();
      formdata.append("categoryName", info?.category?.name);
      $(".forminput").each(function () {
        var key = $(this).data("key");
        var val = $(this).val();
        formdata.append(key, val);
      });
      formdata.append("name", info?.name);

      formdata.append("voucher_product_id", $("#voucher_product_id").val());
      formdata.append("voucher_category_id", $("#voucher_category_id").val());
      formdata.append("payment_id", $("#payment_id").val());

      formdata.append("amount", info?.sale_price);

      axios.post("/api/voucher-order", formdata).then((dt) => {
        if (dt?.data?.message) {
          // const dta= parse(dt?.data?.message)
          // Swal.fire(dta)
          // Swal.fire(dt?.data?.message)
        }

        if (dt?.data?.status) {
          window.location.replace(dt?.data?.data);
        }else{
          toast.error(dt?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
         });
        }
      });
    } else {
      history("/login");
      toast.info("Please login first then try again!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  if (!allData.category) {
    return (
      <div className="w-[100vw] h-[110vh] bg-black flex justify-center items-center z-[10000] fixed -top-10  ">
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#4fa94d"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <>
      <div className="-mt-0.5">
        {allData?.category?.banner ? (
          <img
            src={allData?.category?.banner}
            className="md:block hidden w-full  object-fill max-h-[500px] "
            alt=""
          />
        ) : (
          <img
            src={image}
            className="md:block hidden w-full  object-fill max-h-[500px] "
            alt=""
          />
        )}
        {allData?.category?.banner ? (
          <img
            src={allData?.category?.banner}
            className="w-full object-fill md:hidden h-[210px] "
            alt=""
          />
        ) : (
          <img
            src={image}
            className="w-full object-fill md:hidden h-[210px] "
            alt=""
          />
        )}
      </div>
      <div className="md:bg-[rgba(17,17,17,.8);] pb-8 md:bg-transparen rounded-t-lg md:-mt-48 -mt-32 container ">
        <div className="grid md:grid-cols-2 md:gap-10 md:mt-20 mt-8">
          <div className="md:block hidden">
            {details && (
              <div className="bg-[#222222] text-white px-6  md:px-10 py-4 md:py-6 rounded-lg md:mt-0 mt-8">
                <h2 className="text-[25px] md:text-[35px]">Product Details</h2>
                <div className="font-mediums"> {parse(details)}</div>
              </div>
            )}
          </div>
          <div>
            <div className="md:bg-[#222222] bg-[rgba(34,34,34,.9);] p-6 md:p-10 rounded-lg">
              <PageTitle
                title={allData?.category?.name + " - FNZ Gaming Shop"}
                description="Page Description"
              ></PageTitle>
              <div>
                <h2 className="text-[30px] md:text-[50px] text-white ">
                  {allData?.category?.name}
                </h2>
                <img className=" w-[200px] -mt-2 mb-2" src={hr} alt="" />
                <h3 className="md:text-[20px] text-[18px] text-white font-medium pb-4 uppercase ">
                  Select amount
                </h3>
              </div>
              <div className="grid grid-cols-2 lg:grid-cols-3 md:grid-cols-2 gap-x-4 md:gap-x-8 gap-y-4 md:gap-y-6">
                {allData?.products?.map((item) => (
                  <SelectItems
                    info={info}
                    setInfo={setInfo}
                    key={item?.id}
                    item={item}
                  />
                ))}
              </div>
            </div>

            {info?.note && (
              <div className="bg-[#222222] text-white px-6  md:px-10 py-4 md:py-6 rounded-lg mt-8">
                <p className="text-xl"> {info?.note}</p>
              </div>
            )}
            {info.id && (
              <div className="bg-[#222222] text-white px-6  md:px-10 py-4 md:py-6 rounded-lg mt-8">
                <p className="font-medium">
                  পণ্যটি কিনতে আপনার{" "}
                  <span className="text-[#ff2121] text-2xl px-2">
                    {" "}
                    {info?.sale_price_view}
                  </span>{" "}
                  টাকা প্রয়োজন। <span className="text-2xl">PAY NOW</span> ক্লিক
                  করুন এবং পরবর্তী নির্দেশনা দেখুন।
                </p>
              </div>
            )}
            {info.id && (
              <>
                <h3 className=" mt-8  md:text-[20px] text-[18px] text-white font-medium pb-4 uppercase ">
                  Select Payment Method
                </h3>
                <div className=" mt-1 grid grid-cols-2 lg:grid-cols-3 md:grid-cols-2 gap-x-4 md:gap-x-8 gap-y-4 md:gap-y-6">
                  {payments?.map((item) => (
                    <SelectPayment
                      payment={payment}
                      setPayment={setPayment}
                      key={item?.id}
                      item={item}
                    />
                  ))}
                </div>
              </>
            )}
            {info.id && payment.id && (
              <div className="bg-[#222222] md:w-1/2 w-full mx-auto text-white px-6  md:px-10 py-4 md:py-6 rounded-lg mt-8">
                <form onSubmit={handleSubmit} className="w-full">
                  <input
                    type="hidden"
                    id="voucher_product_id"
                    name="voucher_product_id"
                    value={info?.id}
                    required
                  />
                  <input
                    type="hidden"
                    id="voucher_category_id"
                    name="voucher_category_id"
                    value={info?.voucher_category_id}
                    required
                  />
                  <input
                    type="hidden"
                    id="payment_id"
                    name="payment_id"
                    value={payment?.id}
                    required
                  />
                  {parse(htmltext)}

                  <button
                    type="submit"
                    className="relative inline-flex items-center px-12 py-3 overflow-hidden md:text-lg text-base font-medium text-white border-2 border-white hover:border-[#DD2476] rounded-full hover:text-white group hover:bg-gray-50"
                  >
                    <span className="absolute left-0 block w-full h-0 transition-all bg-gradient-to-r from-[#FA5252] to-[#DD2476] opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                    <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        ></path>
                      </svg>
                    </span>
                    <span className="relative">Pay Now</span>
                  </button>
                </form>
              </div>
            )}
            <div className="bg-[#222222] px-6 text-white  md:px-10 py-4 md:py-6 rounded-lg mt-8">
              <h2 className="text-[25px] md:text-[35px]">How to Order?</h2>
              <iframe
                className="w-[100%]  aspect-video  "
                src={`${
                  allData?.category?.video_url
                    ? allData?.category?.video_url
                    : "https://www.youtube.com/embed/Vj0Ibc-yzjQ"
                }`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="md:hidden block">
            {details && (
              <div className="bg-[#222222] text-white px-6  md:px-10 py-4 md:py-6 rounded-lg md:mt-0 mt-8">
                <h2 className="text-[25px] md:text-[35px]">Product Details</h2>
                <div className="font-medium"> {parse(details)}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleVoucher;
