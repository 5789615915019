import React, { useEffect, useRef, useState } from 'react';
import hr from "../../assets/hr1.png"
import UseData from '../Hooks/UseData';
import $ from 'jquery'
import 'datatables.net-responsive'
import PageTitle from '../PageTitle';

const History = () => {
    const {token} = UseData()
    $.DataTable = require('datatables.net')
    const tableRef = useRef()

    useEffect(() => {
        var table = $(tableRef.current).DataTable({
            responsive: true,
            processing: true,
            serverSide: true,
            stateSave: true,
    "bDestroy": true,
            columnDefs: [
                { responsivePriority: 1, targets: 4, width: 100 },
            ],
            ajax: {
                
                url: "https://backend.fnzgamingshop.com/api/user/lottery-history?page=${page}",
                dataType: "json",
                type: "get",

                beforeSend: function (xhr) {
                    xhr.setRequestHeader(
                        "Authorization",
                        `Bearer ${token?.api_token}`
                    );
                },
            },
            columns: [
                {
                    data: "DT_RowIndex",
                    name: "DT_RowIndex",
                    orderable: false,
                    searchable: false,
                },
                { data: "created_at", name: "created_at" },
                { data: "lottery", name: "lottery" },
              
                { data: "amount", name: "amount" },
                { data: "winner", name: "winner" },
            ],
        });
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    },[])

    return (
        <>
        <PageTitle title="Draw History - FNZ Gaming Shop" description="Page Description"></PageTitle>
            <div className='container py-8'>
                <h2 className='text-[25px] md:text-[35px] text-white '>DRAW HISTORY</h2>
                <img className=' w-[250px] -mt-2 mb-6' src={hr} alt="" />
                <div className="relative overflow-x-hi bg-[#222222]">
                    <table ref={ tableRef } className="display responsive nowrap my-table w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-white uppercase  ">
                            <tr className='my border-b border-[#c07e00]'>
                                <th scope="col" className="md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap">
                                    #
                                </th>
                                <th scope="col" className="md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap">
                                Purchase Date
                                </th>
                                <th data-priority="1" scope="col" className="md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap">
                                Lottery
                                </th>
                                <th scope="col" className="md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap">
                                Amount
                                </th>
                                <th scope="col" className="md:px-6 py-4 px-1 font-semibold md:text-[20px] text-[16px] text-white whitespace-nowrap">
                                Status
                                </th>			
                            </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
 
export default History;
