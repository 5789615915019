const SelectItems = ({ item, payment, setPayment }) => {
  return (
    <>
      {item?.status === "Active" ? (
        <div
          onClick={() => setPayment(item)}
          className={`${
            payment?.id === item?.id
              ? " border-[#e12b70] bg-[#111111] "
              : "hover:border-[#e12b70] border-gray-700"
          } cursor-pointer col-span-1 border  text-center py-2 rounded-md  `}
        >
          <img
            className="md:h-32 h-20 mt-2 cursor-pointer"
            src={`https://backend.fnzgamingshop.com${item?.logo}`}
            alt=""
          />
          <h6
            className={`${
              payment?.id === item?.id
                ? "border-[#e12b70]"
                : "hover:border-[#e12b70]"
            } text-[#29cef6] md:text-2xl `}
          >
            {item?.name}
          </h6>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default SelectItems;
