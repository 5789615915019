import { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import PageTitle from "../PageTitle";
const parse = require("html-react-parser");

const MobileRecharge = () => {

  const [type, setType] = useState([]);
  const [info, setInfo] = useState({});

  const [amount, setAmount] = useState("");

  useEffect(() => {
    // toast.error("Wallet Recharge is not available right now", {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "dark",
    // });
    // history("/wallet");
    axios.get("/api/payment-methods").then((dt) => {
      setType(dt?.data?.data);
    });
  }, []);

  const handlePayment = (payload) => {
    axios.get(`/api/payment-methods/${payload}`).then((dt) => {
      if (dt?.data?.status) {
        setInfo(dt?.data);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (amount > 5) {
      const formData = new FormData();
      formData.append("payment_id", info?.data?.id);
      formData.append("amount", amount);

      axios.post(`/api/wallet-payment`, formData).then((dt) => {
        if (dt?.data?.status) {
          window.location.href = dt?.data?.data;
        }
      });
    }
  };

  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    toast.info("Copied Successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  return (
    <>
      <PageTitle
        title="Recharge - FNZ Gaming Shop"
        description="Page Description"
      ></PageTitle>
      {info.status ? (
        <div className="absolute   left-0 top-0 w-[100vw] h-[100vh] z-[1000000] bg-[#111111] ">
          <div className="container h-full flex justify-center items-center md:py-10">
            <div className="md:w-4/6 lg:w-3/6 w-full bg-[#222222]  py-6 mx-auto ">
              <img
                className="h-20 text-center mx-auto mb-6 "
                src={`https://backend.fnzgamingshop.com${info?.data?.logo}`}
                alt=""
              />
              <span className="h-[6px] w-full block bg-[#fd648b]"></span>
              <h3 className="flex text-white items-center justify-center md:text-[35px] text-[25px] py-3 pl-6  ">
                © FNZ GAMING SHOP
              </h3>
              <div className="px-6">
                {/* <CopyToClipboard text={info?.data?.mobile} onCopy={onCopyText}>
                  <div className="relative my-8 w-full inline-flex items-center justify-between px-12 py-3 text-white overflow-hidden md:text-lg text-base font-medium  border-2  rounded-full  group ">
                    <span className="absolute left-0 block w-full h-0 transition-all  opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                    <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        ></path>
                      </svg>
                    </span>
                    <span className="relative">
                      Send Money No: {info?.data?.mobile}{" "}
                      {isCopied ? (
                        <button className="bg-[#fd648b] px-8 py-2 text-white ml-3 rounded-xl ">
                          Copied
                        </button>
                      ) : (
                        <button className="bg-[#fd648b] px-8 py-2 text-white ml-3 rounded-xl">
                          Copy
                        </button>
                      )}{" "}
                    </span>
                  </div>
                </CopyToClipboard> */}
                <form onSubmit={handleSubmit}>
                  <div className=" gap-4">
                    <div className="form-group mb-6">
                      <label
                        className="text-white md:text-lg text-base  tracking-wider "
                        htmlFor="exampleInput123"
                      >
                        Enter Recharge Amount:
                      </label>
                      <input
                        onChange={(e) => setAmount(e.target.value)}
                        type="number"
                        className="form-control input-2"
                        id="exampleInput123"
                        aria-describedby="emailHelp123"
                        placeholder="Enter Recharge Amount"
                      />
                    </div>
                  </div>
                  {/* <div className="form-group mb-6">
                    <label
                      className="text-white md:text-lg text-base tracking-wider "
                      htmlFor="exampleInput123"
                    >
                      {info?.data?.mobile_field_label}
                    </label>
                    <input
                      onChange={(e) => setMobile(e.target.value)}
                      pattern={info?.data?.mobile_field_pattern}
                      type={info?.data?.mobile_field_type}
                      maxLength={info?.data?.mobile_field_max_length}
                      minLength={info?.data?.mobile_field_min_length}
                      className="form-control input-2"
                      id="exampleInput125"
                      placeholder={info?.data?.mobile_field_placeholder}
                      required
                    />
                  </div>
                  <div className="form-group mb-6">
                    <label
                      className="text-white md:text-lg text-base tracking-wider "
                      htmlFor="exampleInput123"
                    >
                      {info?.data?.tranid_field_label}
                    </label>
                    <input
                      onChange={(e) => setTranid(e.target.value)}
                      type="text"
                      pattern={info?.data?.tranid_field_pattern}
                      maxLength={info?.data?.tranid_field_max_length}
                      minLength={info?.data?.tranid_field_min_length}
                      className="form-control input-2"
                      id="exampleInput126"
                      placeholder={info?.data?.tranid_field_placeholder}
                      required
                    />
                  </div> */}
                  <h2 className="text-[30px] md:text-[35px] text-white  py-2 ">
                    Payment Process{" "}
                  </h2>
                  <ul className="list-disc list-inside text-white">
                    {parse(info?.data?.description)}
                  </ul>
                  <button className="relative mt-2 inline-flex items-center px-12 py-3 overflow-hidden md:text-lg text-base font-medium text-[#fd648b] border-2 border-[#fd648b] rounded-full hover:text-white group hover:bg-gray-50">
                    <span className="absolute left-0 block w-full h-0 transition-all bg-[#fd648b] opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                    <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                      <svg
                        className="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M14 5l7 7m0 0l-7 7m7-7H3"
                        ></path>
                      </svg>
                    </span>
                    <span className="relative">Submit</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="absolute   left-0 top-0 w-[100vw] h-[100vh] z-[1000000] bg-[#111111] ">
          <div className="container h-full flex justify-center items-center md:py-10">
            <div className="md:w-4/6 lg:w-3/6 w-full bg-[#222222]  py-6 mx-auto ">
              <img
                className="h-20 text-center mx-auto mb-6 "
                src={logo}
                alt=""
              />
              <span className="h-[6px] w-full block bg-[#fd648b]"></span>
              <div className="flex justify-center gap-8">
                {type.length > 0 ? (
                  type?.map(
                    (item) =>
                      item.slug !== "wallet" && (
                        <div key={item.id} onClick={() => handlePayment(item?.id)}>
                          <img
                            className="h-32 cursor-pointer "
                            src={`https://backend.fnzgamingshop.com${item?.logo}`}
                            alt=""
                          />
                          <p className="text-white text-center cursor-pointer">{item?.name}</p>
                        </div>
                      )
                  )
                ) : (
                  <div className="w-full h-[100%] bg-black flex justify-center items-center ">
                    <ThreeDots
                      height="80"
                      width="80"
                      radius="9"
                      color="#4fa94d"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileRecharge;
