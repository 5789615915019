import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import hr from "../assets/hr1.png"
const parse = require('html-react-parser')

const Offer = () => {
    const { productName } = useParams()
    const [allData, setAllData] = useState([])
    const [htmlText, setHtmlText] = useState("")

    useEffect(() => {
        axios.get((`/api/offer-zones/${productName}`)).then(dt => {
            setAllData(dt?.data?.data);
            setHtmlText(JSON.parse(dt?.data?.data?.description))

        })
    }, [])

    const [currentTime, setCurrentTime] = useState(Date.now());
    const targetTime = new Date(`${allData?.end_at}`).getTime();
    const timeBetween = targetTime - currentTime;

    const seconds = Math.floor((timeBetween / 1000) % 60);
    const minutes = Math.floor((timeBetween / 1000 / 60) % 60);
    const hours = Math.floor((timeBetween / (1000 * 60 * 60)) % 24);
    const days = Math.floor(timeBetween / (1000 * 60 * 60 * 24));

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(Date.now());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className='container'>
            <h2 className='text-[30px] md:text-[50px] text-white pt-10  uppercase '>{allData?.name}</h2>
            <img className=' w-[200px] -mt-2 mb-2' src={hr} alt="" />
            <h3 className='md:text-[20px] text-[18px] text-white font-medium pb-4 '>HOME / INGAMES</h3>
            <div className='grid bg-[#222222] px-6 py-6 time grid-cols-12 mt-6 md:gap-8'>
                <div className='col-span-12 md:col-span-5'>
                    <img className=' w-[100%] md:w-full rounded-md' src={`https://backend.fnzgamingshop.com${allData?.photo}`} alt="" />
                </div>
                <div className="col-span-12 md:col-span-7 text-white ">
                    <div className='flex justify-between items-center'>
                        <div>
                            <h3 className='text-[25px] md:text-[35px] '>{allData?.name}</h3>
                            {/* <h6 className='md:text-lg text-base' >Regular Price</h6>
                            <h5 className='text-[25px] md:text-[35px]  ' >{allData?.price}</h5>
                            <h6 className='md:text-lg text-base' >Offer Price</h6>
                            <h5 className='text-[25px] md:text-[35px]  ' >{allData?.discount_price}</h5> */}
                        </div>
                        {
                            timeBetween > 0 ? <div>
                            <p className='text-2xl text-center'>Offer Ending Time</p>
                            <p className="text-3xl flex gap-2">
                                <div>
                                    <span className='w-10 h-10  bg-red-500 flex justify-center items-center'>{days} </span>
                                    <p className='text-lg text-center'>Days</p>
                                </div>
                                <div>  <span className='w-10 h-10  bg-red-500 flex justify-center items-center'>{hours} </span><p className='text-lg text-center'>Hour</p></div>
                                <div><span className='w-10 h-10  bg-red-500 flex justify-center items-center'>{minutes} </span><p className='text-lg text-center'>Min</p></div>
                                <div>   <span className='w-10 h-10  bg-red-500 flex justify-center items-center'>{seconds}</span><p className='text-lg text-center'>Sec</p></div>
                            </p>

                        </div> :<div>
                            <p className='text-2xl text-center'>No Offer Available</p>
                            <p className="text-3xl flex gap-2">
                                <div>
                                    <span className='w-10 h-10  bg-red-500 flex justify-center items-center'>00 </span>
                                    <p className='text-lg text-center'>Days</p>
                                </div>
                                <div>  <span className='w-10 h-10  bg-red-500 flex justify-center items-center'>00 </span><p className='text-lg text-center'>Hour</p></div>
                                <div><span className='w-10 h-10  bg-red-500 flex justify-center items-center'>00 </span><p className='text-lg text-center'>Min</p></div>
                                <div>   <span className='w-10 h-10  bg-red-500 flex justify-center items-center'>00</span><p className='text-lg text-center'>Sec</p></div>
                            </p>

                        </div>
                        }

                    </div>

                    {
                        timeBetween > 0 &&  <>  <p className='text-base md:text-lg'>Contact at Telegram only</p> <a target="_blank" href='https://t.me/fnzgs' className="relative inline-flex mt-8 items-center px-12 py-3 overflow-hidden md:text-lg text-base font-medium text-white border-2 border-white hover:border-[#DD2476] rounded-full hover:text-white group hover:bg-gray-50">
                        <span className="absolute left-0 block w-full h-0 transition-all bg-gradient-to-r from-[#FA5252] to-[#DD2476] opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                        <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                        </span>
                        <span className="relative">Click For Buy</span>
                    </a></>
                    }

                </div>
            </div>
            <div className='bg-[#222222] text-white px-6  md:px-10 py-4 md:py-6 rounded-lg mt-8'>
                <h2 className='text-[25px] md:text-[35px]' >Short Description</h2>
                <img src={hr} alt="" />
                <p className='font-medium  text-lg mt-4'>{allData?.name}</p>
            </div>
            <div className='bg-[#222222] px-6 text-white p  md:px-10 py-4 md:py-6 rounded-lg mt-8'>
                <h2 className='text-[25px] md:text-[35px] ' >Description</h2>
                <img src={hr} alt="" />
                <p className='font-medium text-lg leading-10 mt-3 '>{parse(htmlText)}</p>
            </div>
        </div>
    );
};

export default Offer;
