import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContextProvider from "./components/context/ContextProvider";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
   <React.StrictMode>
      <ContextProvider>
         <App />
         <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
         />
      </ContextProvider>
   </React.StrictMode>
);

reportWebVitals();
