import React from 'react';
import { Link } from 'react-router-dom';
import hr from "../assets/hr1.png"
import UseData from './Hooks/UseData';

const GiftDetails = ({product}) => {

    return (
        <>
        <div className="col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2  ">
            <Link to={`/gift/${product?.slug}`} className="rounded-md ">
                <div className="relative rounded-md bg-[#111111] ">
                    <span className="absolute z-10 left-2 top-[9px] px-6 font-normal rounded-sm pt-[4px] pb-[3px] bg-[#e12b70] text-white  ">Gift</span>
                    <div className='overflow-hidden rounded-md border  time border-[#c07e00] hover:border-[#e12b70] '>
                        <img src={product?.photo} alt="PUBGM (GLOBAL)" className="rounded-t-md transform time hover:scale-125 " />
                    </div>
                    <div className="text-center pt-4">
                        <h5 className="md:text-[20px] text-[18px] text-white font-medium pb-3 ">
                            {product?.title}
                        </h5>
                    </div>
                </div>
            </Link>
        </div>
    </>
    );
};

export default GiftDetails;