import {useNavigate, useParams} from 'react-router-dom';
import logo from "../../assets/logo.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import CopyToClipboard from "react-copy-to-clipboard";
import {toast} from 'react-toastify';

const OrderStatus = () => {
    const { orderId } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  useEffect(() => {

    if (orderId) {
      axios
        .get("/api/voucher-order-details/"+orderId)
        .then((response) => {
          setOrderData(response?.data?.data);
        })
        .catch((error) => {
          console.error("Error verifying payment:", error);
        });
    }
  }, [orderId]);

  const history = useNavigate();
  const handleRoute = () => {
    history("/voucher-orders");
  };

  const onCopyText = (e) => {
    // e.preventDefault();
    toast.info("Copied Successfully", {
       position: "top-right",
       autoClose: 5000,
       hideProgressBar: false,
       closeOnClick: true,
       pauseOnHover: true,
       draggable: true,
       progress: undefined,
       theme: "dark",
    });
    setIsCopied(true);
 };

  return (
    <div className="absolute  left-0 top-0 w-[100vw] pb-10 h-[100vh] z-[1000000] bg-[#111111] ">
      <div className="container  flex justify-center  items-center md:py-10 h-full  ">
        <div className="md:w-4/6 lg:w-3/6 w-full bg-[#222222] overflow-my  py-6 mx-auto ">
          <img className="h-20 text-center mx-auto mb-6 " src={logo} alt="" />
          <span className="h-[6px] w-full block bg-[#fd648b]"></span>
          <div className="flex justify-center px-8 py-3 text-white text-2xl gap-8">
            <h3 className="text-center text-3xl py-3 ">© FNZ GAMING SHOP</h3>
          </div>

          {orderData ? (
            <>
              <div className="grid mx-4 grid-cols-2 px-8 border-b border-r border-t border-l border-[#fd648b]  text-xl  text-white gap-8">
                <h4 className="col-span-1 border-r  py-3 border-[#fd648b]">
                  Order NO
                </h4>
                <h4 className="col-span-1 py-3 text-right  ">
                  {orderData.order_code}
                </h4>
              </div>
              <div className="grid mx-4 grid-cols-2 px-8 border-b border-r border-t border-l border-[#fd648b]  text-xl  text-white gap-8">
                <h4 className="col-span-1 border-r  py-3 border-[#fd648b]">
                  Game
                </h4>
                <h4 className="col-span-1 py-3 text-right  ">
                  {orderData.category}
                </h4>
              </div>
              <div className="grid mx-4 grid-cols-2 px-8 border-b border-r border-t border-l border-[#fd648b]  text-xl  text-white gap-8">
                <h4 className="col-span-1 border-r  py-3 border-[#fd648b]">
                  Product
                </h4>
                <h4 className="col-span-1 py-3 text-right  ">
                  {orderData.product}
                </h4>
              </div>
              <div className="grid mx-4 grid-cols-2 px-8 border-b border-r border-t border-l border-[#fd648b]  text-xl  text-white gap-8">
                <h4 className="col-span-1 border-r  py-3 border-[#fd648b]">
                  Amount
                </h4>
                <h4 className="col-span-1 py-3 text-right  ">
                  {orderData.amount}
                </h4>
              </div>
              
              {orderData.payment_status === "Paid" &&
                orderData.order_status === "Completed" && (
                  <div className="grid mx-4 grid-cols-2 px-8 border-b border-r border-t border-l border-[#fd648b]  text-xl  text-white gap-8">
                    <h4 className="col-span-1 border-r  py-3 border-[#fd648b]">
                      Voucher Code ({orderData.product})
                    </h4>
                    <h4 className="col-span-1 py-3 text-right  ">
                      <CopyToClipboard
                        text={orderData?.voucher_code}
                        onCopy={onCopyText}
                      >
                        <span className="relative">
                          {orderData?.voucher_code}
                          {isCopied ? (
                            <div className="bg-[#fd648b] inline-block px-4 md:px-8 py-2 text-white ml-3 rounded-xl ">
                              Copied
                            </div>
                          ) : (
                            <div className="cursor-pointer bg-[#fd648b] inline-block px-4 md:px-8 py-2 text-white ml-3 rounded-xl">
                              Copy
                            </div>
                          )}{" "}
                        </span>
                      </CopyToClipboard>
                    </h4>
                  </div>
                )}

              <button
                onClick={handleRoute}
                className="relative inline-flex mt-8 items-center px-12 py-3 ml-8 overflow-hidden md:text-lg text-base font-medium text-white border-2 border-white hover:border-[#DD2476] rounded-full hover:text-white group hover:bg-gray-50"
              >
                <span className="absolute left-0 block w-full h-0 transition-all bg-gradient-to-r from-[#FA5252] to-[#DD2476] opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400 ease"></span>
                <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0 ease">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    ></path>
                  </svg>
                </span>
                <span className="relative">View All Orders</span>
              </button>
            </>
          ) : (
            <>
              <div>
                <h3 className="md:text-[30px] text-[24px] font-bold text-white py-2 text-center">
                  Voucher is being processed...
                </h3>
              </div>
              <div className="w-full h-[100%] bg-black flex justify-center items-center ">
                <ThreeDots
                  height="80"
                  width="80"
                  radius="9"
                  color="#4fa94d"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderStatus;
